

























































import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';
import { LocaleMessages } from 'vue-i18n';
import Icon from '@/components/Icon.vue';

export interface DropdownItem {
    icon?: string;
    iconFilled?: boolean;
    divider?: boolean;
    name: string | LocaleMessages;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: (params: any, index: number) => void;
    dataFilter?: string;
    isSelected?: boolean;
}

@Component({
    components: { Icon },
})
export default class Dropdown extends Vue {
    @Prop({ default: () => [] }) dropdownItems!: Array<DropdownItem>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() readonly param!: any;

    @Prop(Boolean) readonly right!: boolean;

    @Prop(Boolean) readonly disabled!: boolean;

    @Prop(String) readonly simpleHeaderTitle!: string;

    @Prop(String) readonly simpleHeaderSubtitle!: string;

    isCursorOutside = true;

    isOpen = false;

    open(): void {
        this.isOpen = !this.isOpen && !this.disabled;
    }

    clickedOutside() {
        this.isOpen = false;
    }

    pressAction(index: number) {
        this.isOpen = !this.isOpen;
        this.dropdownItems[index].action(this.param, index);
    }
}
