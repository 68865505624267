




































import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';
import Button from '@/atomics/Button.vue';
import InputGroup from '@/components/form/InputGroup.vue';
import idealName from '@/utils/ideal-name';
import { AgentMetadata } from '@/store/modules/agent';

@Component({
    components: {
        Modal,
        Icon,
        Button,
        InputGroup,
    },
})
export default class ModalUpdateAgent extends Vue {
    @Prop(String) readonly name!: string;

    private isStored = true;

    private lastStoredMetadata = { name: '' };

    set nameAgent(name: string) {
        if (this.isStored) {
            this.isStored = false;
            this.lastStoredMetadata.name = this.nameAgent || '';
        }
        this.$store.dispatch('changeAgentMetadata', { name: this.name, metadata: { name } });
    }

    get nameAgent(): string {
        return this.metadata().name || '';
    }

    get cleanedName() {
        return idealName(this.name);
    }

    get agent() {
        return this.$store.getters.getAgentByName(this.name);
    }

    metadata(): AgentMetadata {
        return this.$store.getters.agentMetadata(this.name);
    }

    confirm() {
        this.isStored = true;
        this.$store.dispatch('updateAgentMetadata', { name: this.name, metadata: { name: this.nameAgent } });
        this.toogleModal();
    }

    toogleModal() {
        if (!this.isStored) {
            this.$store.dispatch('changeAgentMetadata', { name: this.name, metadata: this.lastStoredMetadata });
            this.isStored = true;
        }
        (this.$refs.modal as ModalUpdateAgent).toogleModal();
    }
}
