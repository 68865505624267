/* eslint-disable @typescript-eslint/camelcase */
export default {
    internal: 'internal',
    team: 'team',
    dettach_queue: 'Unlink',
    edit: 'Edit',
    confirm: 'Confirm',
    edit_agent: 'Edit agent',
    extension_number: 'Extension number',
    nothing_selected: 'Nothing selected',
    queue: 'Queue | Queues',
    assign_queue: 'Assign queue',
    assignament_queue_description: 'Here you can assign to agent {agent} the next queues:',
    agents: 'Agents',
    more_detail: 'More detail',
    hangup: 'Hangup',
    hangup_call: 'Hangup call',
    ringing: 'Ringing',
    do_not_disturb: 'Do not disturb',
    dnd: 'Do not disturb',
    do_available: 'Do available',
    do_not_show_anymore: 'Do not show anymore',
    panel_settings: 'Panel settings',
    visual_panel_settings: 'Visual panel settings',
    waiting_calls: 'There is at least 1 waiting call in these queues:',
    waiting_calls_activate: 'See notifications for waiting calls',
    no_answer: 'No answer',
    call: {
        incoming: 'Incoming',
        in_progress: 'In progress',
    },
    agent: {
        form: {
            name: 'Name',
            edit_description: 'Here you can edit some attributes of the agent {agent}:',
        },
        agent: 'Agent | Agents',
        status: {
            logged_out: 'Logged out',
            available: 'Available',
            available_on_demand: 'Available on demand',
            on_break: 'On break',
            not_assign: 'Not assigned',
        },
        state: {
            idle: 'Idle',
            waiting: 'Waiting',
            receiving: 'Receiving',
            in_queue_call: 'In queue call',
            ready: 'Resume agent',
            absent: 'Pause agent',
        },
        custom_status: {
            busy: 'Busy',
            available: 'Available',
            unavailable: 'Unavailable',
            agitated: 'Ringing',
            online: 'Online',
            break: 'On break',
            offline: 'Offline',
        },
    },
    callcenter: {
        abandoned: 'Abandoned',
        abandoned_rate: 'Abandoned rate',
        answered: 'Answered',
        average_talk_seconds: 'Avg. Talk',
        average_wait_seconds: 'Avg. Wait',
        logins: 'Logins',
        logoff: 'Logoff',
        max_wait_time_seconds: 'Max wait',
        received: 'Received',
        unanswered: 'Unanswered',
    },
};
