<template>
    <transition
        enter-active-class="transition-all duration-150 ease-in"
        leave-active-class="transition-all duration-150 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div class="wpp-animation-container">
            <div class="wpp-elements-container">
                <div class="wpp-element one bg-gray-900 dark:bg-white">
                    <span class="wpp-ex-container">
                        <span class="dark:bg-white" />
                    </span>
                </div>

                <div class="wpp-element two">
                    <span class="wpp-ex-container">
                        <span class="bg-gray-900 dark:bg-white" />
                    </span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {};
</script>

<style scoped>
.wpp-animation-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(45,45,45,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 55555;
}
.wpp-elements-container {
    position: relative;
    overflow: hidden;
    width: 130px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: exElementSpans;
    animation-duration: 1s;
    animation-delay: .5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform: scale(.7);
}
@keyframes exElementSpans {
    from{
        transform: scale(.7) rotate(0);
    }
    50% {
        transform: scale(1) rotate(0);
    }
    75% {
        transform: scale(.7) rotate(0);
    }
    to {
        transform: scale(.2) rotate(360deg);
    }
}
.wpp-elements-container .wpp-element {
    position: relative;
    height: 180px;
    overflow: hidden;
}
.wpp-elements-container .wpp-element.one {
    z-index: 55556;
    width: 10px;
    margin: 17px 0 0 0;
    transform: translateX(-180px);
    animation-name: exElementOne;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}
.wpp-elements-container .wpp-element.two {
    transform: rotate(-40deg);
    width: 18px;
    z-index: 55557;
    background: #ea5455;
    margin: 17px 0 0 0;
    transform: translateX(180px);
    animation-name: exElementTwo;
    animation-duration: 1.5s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}
@keyframes exElementOne {
    from{
        transform: translateX(-180px) rotate(0);
    }
    50% {
        transform:  translateX(0) rotate(0);
    }
    to {
        transform:  translateX(0) rotate(40deg);
    }
}
@keyframes exElementTwo {
    from{
        transform: translateX(180px) rotate(0);
    }
    50% {
        transform:  translateX(0) rotate(0);
    }
    to {
        transform:  translateX(0) rotate(-40deg);
    }
}
.wpp-elements-container .wpp-element .wpp-ex-container {
    overflow: hidden;
}
.wpp-elements-container .wpp-element .wpp-ex-container span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    right: -20px;
    z-index: 55558;
}
.wpp-elements-container .wpp-element.one .wpp-ex-container span {
    background: #ea5455;
    transform: translateY(-95px);
}
.wpp-elements-container .wpp-element.two .wpp-ex-container span {
    transform: translateY(-107px) rotate(-10deg);
}
</style>
