















import { Vue, Component, Prop } from 'vue-property-decorator';
import icons from '@/assets/icons.json';

enum IconState {
    Stroke = 'stroke',
    Solid = 'solid'
}

enum IconFilled {
    None = 'none',
    CurrentColor = 'currentColor'
}

@Component
export default class Icon extends Vue {
    @Prop(String) readonly name!: string;

    @Prop(Boolean) readonly filled!: boolean;

    state = IconState.Stroke;

    fill = IconFilled.None;

    stroke = IconFilled.CurrentColor;

    viewBox = '0 0 24 24';

    created() {
        if (this.filled) {
            this.fill = IconFilled.CurrentColor;
            this.stroke = IconFilled.None;
            this.state = IconState.Solid;
            this.viewBox = '0 0 20 20';
        }
    }

    get paths() {
        const iconsMap = new Map(Object.entries(icons));
        return iconsMap.get(`${this.state}-${this.name}`);
    }
}
