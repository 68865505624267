







































import { Component, Vue, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';
import Button from '@/atomics/Button.vue';
import SelectMultipleQueue from '@/components/select/multiple/queue/SelectMultipleQueue.vue';
import { Queue as QueueStore } from '@/store/modules/queue';
import Vision, { VisionMode } from '@/utils/vision';

@Component({
    components: {
        Button,
        SelectMultipleQueue,
        Icon,
    },
})
export default class PanelConfig extends Vue {
    @Prop(Array) readonly queues!: Array<QueueStore>;

    @Prop(String) readonly currentDomain!: string;

    @Prop(Number) readonly visionMode!: VisionMode;

    @Prop(Vision) readonly vision!: Vision;

    // eslint-disable-next-line class-methods-use-this
    get viewButtons(): Array<any> {
        return [
            {
                visionType: 'Minimized',
                icon: 'view-boards',
            },
            {
                visionType: 'Intermediate',
                icon: 'view-grid',
            },
            {
                visionType: 'Maximized',
                icon: 'view-list',
            },
        ];
    }
}
