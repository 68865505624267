































import { Vue, Component, Inject } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';
import HttpClient, { PanelResponse } from '@/api/http-client';
import packageJson from '../../package.json';

interface VersionPanel {
    core_version: string;
    web_version: string;
}

@Component({ components: { Modal, Icon } })
export default class ModalVersion extends Vue {
    coreVersion = '0.0.0';

    visualVersion = '0.0.0';

    webVersion = '0.0.0';

    @Inject('httpClient') httpClient!: HttpClient;

    toogle() {
        (this.$refs.modal as Modal).toogleModal();
    }

    created() {
        this.refreshCoreVersion();
        this.visualVersion = packageJson.version;
    }

    refreshCoreVersion() {
        this.httpClient.get('/version').then((resp: PanelResponse) => {
            const data = resp.data as VersionPanel;
            this.coreVersion = data.core_version;
            this.webVersion = data.web_version;
        });
    }
}
