




































































































































































































import Agent from '@/components/Agent.vue';
import Button from '@/atomics/Button.vue';
import Icon from '@/components/Icon.vue';
import Notification from '@/atomics/notifications/Notification.vue';
import QueueChunk from '@/components/QueueChunk.vue';
import Queue from '@/components/Queue.vue';
import SelectMultipleQueue from '@/components/select/multiple/queue/SelectMultipleQueue.vue';
import Select, { SelectOption } from '@/components/form/Select.vue';
import Toogle from '@/components/form/Toogle.vue';
import Tabs, { TabOption } from '@/components/Tabs.vue';
import Vision, { VisionMode } from '@/utils/vision';
import WebSocket from '@/socket';
import Veil from '@/atomics/Veil.vue';
import { Channel } from 'phoenix';
import {
    Component,
    Vue,
    Inject,
    Prop,
    Provide,
} from 'vue-property-decorator';
import {
    Status,
    State,
    CustomStatus,
    AgentCustomStatus,
} from '@/utils/agent';
import createAgentChannel from '@/utils/agent/channel';
import createQueueChannel from '@/utils/queue/channel';
import idealName from '@/utils/ideal-name';
import createMaintenanceChannel from '@/utils/maintenance/channel';
import {
    AgentChannel, OfferAgent, Registration, Agent as AgentStore,
} from '@/store/modules/agent';
import { QueueMemberCall, QueueChannel, Queue as QueueStore } from '@/store/modules/queue';
import Logger from '@/utils/logger/logger';
import Spinner from '@/atomics/loaders/Spinner.vue';
import { UpdateLoadingAgent } from '@/store/modules/agent/events';
import { UpdateLoadingQueue } from '@/store/modules/queue/events';
import PanelCore from '@/core/Panel';
import PanelFilter from '@/components/PanelFilter.vue';
import ModalSettings from '@/components/panel/ModalSettings.vue';
import Tooltip from '@/components/Tooltip.vue';
import Filter from '@/core/panel/Filter';
import Setting from '@/core/panel/Setting';
import HttpClient from '@/api/http-client';
import UserInfo from '@/core/userinfo';
import StorageQueueVisibility from '@/core/queues/StorageVisibility';
import StorageQueueOrder from '@/core/queues/StorageOrder';
import InfoBox from '@/components/InfoBox.vue';
import { maxQueuesVisibleAllowed } from '@/store/modules/queue/getters';
import { toCamelCase } from '@/utils/text';
import PanelConfig from '@/components/panel/PanelConfig.vue';

enum TabFooterOption {
    Agents = 'agents',
    Queues = 'queues'
}

@Component({
    components: {
        Agent,
        Button,
        Icon,
        Notification,
        Queue,
        Select,
        Tabs,
        Toogle,
        QueueChunk,
        Spinner,
        PanelFilter,
        ModalSettings,
        Tooltip,
        Veil,
        SelectMultipleQueue,
        InfoBox,
        PanelConfig,
    },
})
export default class Panel extends Vue {
    tabFooterOptions: Array<TabOption> = [];

    tabFooterActived = TabFooterOption.Agents;

    agents: Array<AgentStore> = [];

    selectedAgents: Array<AgentStore> = [];

    isFooterExpanded = false;

    isFooterMinimized = true;

    queues: Array<QueueStore> = [];

    registrations = new Map<string, Registration>();

    vision: Vision = Vision.new();

    visionMode: VisionMode = VisionMode.Maximized;

    wantedExtension = '';

    statusOptions: Array<SelectOption>= [];

    loadedTime = Date.now();

    maxQueuesVisibleAllowed = maxQueuesVisibleAllowed;

    storageQueueOrder !: StorageQueueOrder;

    protected showWaitingCallsWarning = false;

    protected queuesWithWaitingCalls = '';

    @Inject('logger') logger!: Logger;

    @Inject('httpClient') http!: HttpClient;

    panel: PanelCore = PanelCore.fromView(Filter.fromPanel(), Setting.fromPanel());

    @Prop(UserInfo) readonly userInfo!: UserInfo;

    @Prop(Object) readonly socket!: WebSocket;

    @Provide('storagePrefix') storagePrefix: string = toCamelCase(
        `${this.userInfo.username}-${this.userInfo.currentDomain}`,
    );

    created() {
        this.$store.commit('setStorageVisibility', StorageQueueVisibility.create(this.storagePrefix));

        this.storageQueueOrder = StorageQueueOrder.create(this.storagePrefix);

        this.statusOptions = [
            {
                name: this.$t(`agent.custom_status.${CustomStatus.Available}`).toString(),
                value: CustomStatus.Available,
            },
            {
                name: this.$t(`agent.custom_status.${CustomStatus.Unavailable}`).toString(),
                value: CustomStatus.Unavailable,
            },
            {
                name: this.$t(`agent.custom_status.${CustomStatus.Busy}`).toString(),
                value: CustomStatus.Busy,
            },
            {
                name: this.$t('agent.custom_status.offline').toString(),
                value: CustomStatus.Offline,
            },
            { name: this.$t(`agent.status.${Status.NotAssign}`).toString(), value: '' },
        ];

        this.tabFooterOptions = [
            { name: this.$tc('agent.agent', 2), value: TabFooterOption.Agents, selected: true },
            { name: this.$tc('queue.queue', 2), value: TabFooterOption.Queues, selected: false },
        ];

        const maintenanceChannel = createMaintenanceChannel(this.socket);
        maintenanceChannel.join().receive('error', (response) => {
            this.logger.captureError(response);
            console.error('Unable to join on maintenance', response);
        });

        const agentsChannel: Channel = this.socket.channel('agents:all');
        const queuesChannel: Channel = this.socket.channel('queues:all');

        const joinTimeout = 240000;

        queuesChannel.on('all_queues', (data) => {
            this.initQueues(data.queues);

            data.queues.forEach((queue: QueueChannel) => {
                const updateLoadingQueue: UpdateLoadingQueue = { isLoading: false, name: queue.name };

                const channel = createQueueChannel(queue.name, this.socket, this.$store, () => {
                    this.$store.dispatch('isLoadingQueue', updateLoadingQueue);
                });

                this.$store.dispatch(
                    'addQueueToContainer',
                    {
                        name: queue.name,
                        tiers: [],
                        visible: this.$store.getters.storageVisibility.getQueueVisibility(queue.name),
                        membersCount: 0,
                        callsAnswered: 0,
                        agentOffers: new Map<string, OfferAgent>([]),
                        memberCalls: new Map<string, QueueMemberCall>([]),
                        metadata: { name: queue.metadata?.name },
                        isLoading: true,
                        channel,
                    },
                );
                channel.join(joinTimeout).receive('error', (err) => {
                    this.logger.captureError(err);
                    this.$store.dispatch('isLoadingQueue', updateLoadingQueue);
                }).receive('timeout', () => {
                    this.$store.dispatch('isLoadingQueue', updateLoadingQueue);
                });
            });
        });

        agentsChannel.on('all_agents', (data) => {
            this.agents = data.agents;
            this.registrations = new Map(Object.entries(data.registrations));
            this.selectedAgents = data.agents;

            data.agents.forEach((agent: AgentChannel) => {
                const updateLoadingAgent: UpdateLoadingAgent = { name: agent.name, isLoading: false };

                const channel = createAgentChannel(agent.name, this.socket, this.$store, () => {
                    this.$store.dispatch('isLoadingAgent', updateLoadingAgent);
                });

                this.$store.dispatch(
                    'addAgentToContainer',
                    {
                        name: agent.name,
                        tiers: [],
                        status: Status.None,
                        state: State.None,
                        callStates: new Map(),
                        queueCalls: new Map(),
                        isLoading: true,
                        metadata: { name: agent.metadata.name },
                        connected: this.registrations.has(agent.name),
                        lastActivityAtSecs: 0,
                        channel,
                        previousState: State.None,
                        doNotDisturb: false,
                    },
                );
                channel.join(joinTimeout).receive('error', (err) => {
                    this.$store.dispatch('isLoadingAgent', updateLoadingAgent);
                    this.logger.captureError(err);
                }).receive('timeout', () => {
                    this.$store.dispatch('isLoadingAgent', updateLoadingAgent);
                });
            });
        });

        queuesChannel.on('queues_stats', (data) => {
            const updatedQueueStats = { queueStats: data.queue_stats };
            this.$store.dispatch('updateQueueStats', updatedQueueStats);
        });

        agentsChannel.on('update_registrations', (data) => {
            this.registrations = new Map<string, Registration>(Object.entries(data.registrations));
        });

        agentsChannel.join(joinTimeout).receive('error', (response) => {
            this.logger.captureError(response);
            console.error('Unable to join on agents:all', response);
        });

        queuesChannel.join(joinTimeout).receive('error', (response) => {
            this.logger.captureError(response);
            console.error('Unable to join on queues:all', response);
        });

        this.$root.$on('changeDomain', (index: number) => {
            this.onChangeDomain(index);
        });
    }

    minimizeFooter() {
        this.isFooterMinimized = !this.isFooterMinimized;
        if (this.isFooterMinimized) {
            this.isFooterExpanded = false;
        }
    }

    onChangeOptions(selected: Array<string>) {
        if (selected.length === 0) {
            this.selectedAgents = this.agents;
        } else {
            this.selectedAgents = this.agents.filter((agent) => {
                const agentStore = this.$store.getters.getAgentByName(agent.name);
                const customStatus = AgentCustomStatus.fromAgent(
                    agentStore.status,
                    agentStore.state,
                    agentStore.previousState,
                );
                return (selected.includes(customStatus.status()) && agentStore.connected)
                        || (selected.includes(CustomStatus.Offline) && !agentStore.connected)
                        || (selected.includes('') && agentStore.status === '');
            });
        }
    }

    onChangeFooterOption(option: TabFooterOption) {
        this.tabFooterActived = option;
    }

    isTabFooterAgents() {
        return this.tabFooterActived === TabFooterOption.Agents;
    }

    isTabFooterQueues() {
        return this.tabFooterActived === TabFooterOption.Queues;
    }

    onToogleIdleTime(): void {
        if (this.panel.setting.idleTimeHidden) {
            this.$store.dispatch('refreshConnectedAgentsLastActivity');
        }
        this.panel.setting.toogleIdleTime();
    }

    onToogleAdvancedStats(): void {
        this.panel.setting.toogleAdvancedStats();
    }

    onChangeDomain(index: number): void {
        const { domain } = this.userInfo.customers[index];
        this.http.patch('/user_domain',
            { user: { domain } }, { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } })
            .then(() => location.reload()).catch((error) => console.log(error)); // eslint-disable-line
    }

    changeQueuesOrder(queuesOrder: QueueStore[]) {
        this.queues = [...queuesOrder];

        this.storageQueueOrder.saveCustomOrder(this.queues);
    }

    changeVision(visionToChange: string): void {
        const vision = this.vision as any;

        this.visionMode = vision[visionToChange]();
    }

    toggleSettingsModal(): void {
        const modal = this.$refs.modalSettings as any;

        modal.toogle();
    }

    get footerMinimizedIcon() {
        return this.isFooterMinimized ? 'arrows-expand' : 'minus';
    }

    get filteredAgents() {
        const text = this.wantedExtension.toLowerCase();
        return this.selectedAgents
            .filter((agent): boolean => agent.metadata?.name?.toLowerCase().startsWith(text)
                                   || agent.name.startsWith(text))
            .sort(this.sortAgent);
    }

    protected get userHasWaitingCallsFunctionality() {
        return this.userInfo.username === 'PWRJORGE'
        || this.userInfo.username === 'ABALUARTE3'
        || this.userInfo.username === 'VQUIJANO1'
        || this.userInfo.username === 'JUANGONZALEZ'
        || this.userInfo.username === 'JOVATESTPCX';
    }

    private sortAgent(a: AgentStore, b: AgentStore) {
        if (this.$store.getters.agentExtensionName(a.name) < this.$store.getters.agentExtensionName(b.name)) {
            return -1;
        }
        return 1;
    }

    private initQueues(queues: QueueStore[]) {
        const queuesWithCustomOrder = this.storageQueueOrder.getCustomOrder();

        this.queues = [...queuesWithCustomOrder];

        const queuesNotInStorage = queues.filter((queue) => queuesWithCustomOrder.find(
            (queueWithCustomOrder) => queueWithCustomOrder.name === queue.name,
        ) === undefined);

        queuesNotInStorage.forEach((queue) => {
            this.queues.push(queue);
        });
    }

    protected checkIfShowWaitingCallsWarning(): void {
        const showNotifications = window.localStorage.getItem('showNotifications');

        if (showNotifications === 'false') {
            return;
        }

        let showNotification = false;

        this.showWaitingCallsWarning = false;

        this.queuesWithWaitingCalls = '';

        const minNumberOfWaitingCallsToShowWarning = 1;
        const queuesNames: string[] = [];

        this.queues.forEach((element) => {
            const membersCount = this.$store.getters.queueMembersCount(element.name);

            if (membersCount >= minNumberOfWaitingCallsToShowWarning) {
                queuesNames.push(idealName(element.name));

                showNotification = true;
            }
        });

        if (showNotification) {
            this.showNotification();
        }

        this.queuesWithWaitingCalls = queuesNames.join(', ');
    }

    protected async showNotification() {
        this.showWaitingCallsWarning = true;

        await new Promise((resolve) => setTimeout(resolve, 6000));

        this.showWaitingCallsWarning = false;
    }

    protected hideNotification(): void {
        this.showWaitingCallsWarning = false;
    }
}
