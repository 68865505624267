
















import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';

@Component({})
export default class Queue extends Vue {
    @Prop(String) readonly title!: string;
}
