

















































































import {
    Component,
    Vue,
    Inject,
    Provide,
    Watch,
} from 'vue-property-decorator';

import HttpClient from '@/api/http-client';
import Logger from '@/utils/logger/logger';
import UserInfo from '@/core/userinfo';
import WebSocket from '@/socket';
import { urlWebSocket } from '@/utils/url';
import { Channel } from 'phoenix';
import { toCamelCase } from '@/utils/text';
import {
    AgentChannel, Registration, Agent as AgentStore,
} from '@/store/modules/agent';
import createAgentChannel from '@/utils/agent/channel';
import Icon from '@/components/Icon.vue';
import {
    Status,
    State,
    CustomStatus,
} from '@/utils/agent';
import { UpdateLoadingAgent } from '@/store/modules/agent/events';
import { SelectOption } from '@/components/form/Select.vue';
import Call from '@/components/queue/Call.vue';
import AnimationPicallEx from '@/atomics/animations/AnimationPicallEx.vue';
import NoResults from '@/atomics/screens/NoResults.vue';
import Dropdown from './Dropdown.vue';
import CountDownBar from './CountDownBar.vue';

interface CallObject {
    agent: string;
    direction: string;
    [key: string]: any;
}
@Component({
    components: {
        AnimationPicallEx,
        Call,
        CountDownBar,
        Dropdown,
        Icon,
        NoResults,
    },
})
export default class CallsByAgentContainer extends Vue {
    @Inject('logger') logger!: Logger;

    @Inject('httpClient') http!: HttpClient;

    socket!: WebSocket;

    userInfo: UserInfo = UserInfo.fromInit();

    registrations = new Map<string, Registration>();

    statusOptions: Array<SelectOption>= [];

    selectedAgents = [];

    agentsToShow = [];

    callsInformation: CallObject[] = [];

    protected intervalId = null as any;

    protected countdown = 5;

    protected targetTime = 0;

    protected isCounting = false;

    @Provide('storagePrefix') storagePrefix: string = toCamelCase(
        `${this.userInfo.username}-${this.userInfo.currentDomain}`,
    );

    @Watch('agentsToShow')
    protected onAgentsToShowChange(): void {
        if (this.agentsToShow.length) {
            this.setInitialAgentsOnScreen();
        }
    }

    created() {
        this.getUserInfo();
        this.getAgents();
        this.setInitialAgentsOnScreen();
    }

    mounted() {
        this.startCountdown();
    }

    protected unmounted(): void {
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
        }
    }

    protected startCountdown(): void {
        if (this.isCounting) return;
        this.isCounting = true;

        this.targetTime = Date.now() + this.countdown * 1000;

        this.intervalId = window.setInterval(() => {
            const timeLeft = Math.ceil((this.targetTime - Date.now()) / 1000);
            if (timeLeft >= 0) {
                this.countdown = timeLeft;
            } else {
                clearInterval(this.intervalId!);
                this.intervalId = null;
                this.isCounting = false;
                this.refresh();
            }
        }, 100);
    }

    protected get groupedCalls() {
        const grouped: Record<string, CallObject[]> = {};

        this.callsInformation.forEach((call) => {
            if (!grouped[call.agent]) {
                grouped[call.agent] = [];
            }
            grouped[call.agent].push(call);
        });

        return grouped;
    }

    private getUserInfo() {
        const token = sessionStorage.getItem('token') || '';

        UserInfo.fromApi(this.http)
            .then((userinfo: UserInfo) => {
                this.userInfo = userinfo;

                this.$root.$emit('customers', this.userInfo.customers);
                this.$root.$emit('currentDomain', this.userInfo.currentDomain);
            });

        this.socket = WebSocket.fromToken(urlWebSocket, token, this.logger);
    }

    private getAgents() {
        const agentsChannel: Channel = this.socket.channel('agents:all');

        const joinTimeout = 240000;

        this.statusOptions = [
            {
                name: this.$t(`agent.custom_status.${CustomStatus.Available}`).toString(),
                value: CustomStatus.Available,
            },
            {
                name: this.$t(`agent.custom_status.${CustomStatus.Unavailable}`).toString(),
                value: CustomStatus.Unavailable,
            },
            {
                name: this.$t(`agent.custom_status.${CustomStatus.Busy}`).toString(),
                value: CustomStatus.Busy,
            },
            {
                name: this.$t('agent.custom_status.offline').toString(),
                value: CustomStatus.Offline,
            },
            { name: this.$t(`agent.status.${Status.NotAssign}`).toString(), value: '' },
        ];

        agentsChannel.on('all_agents', (data) => {
            this.setAgentsToShow(data.agents);

            this.registrations = new Map(Object.entries(data.registrations));

            data.agents.forEach((agent: AgentChannel) => {
                const updateLoadingAgent: UpdateLoadingAgent = { name: agent.name, isLoading: false };

                const channel = createAgentChannel(agent.name, this.socket, this.$store, () => {
                    this.$store.dispatch('isLoadingAgent', updateLoadingAgent);
                });

                this.$store.dispatch(
                    'addAgentToContainer',
                    {
                        name: agent.name,
                        tiers: [],
                        status: Status.None,
                        state: State.None,
                        callStates: new Map(),
                        queueCalls: new Map(),
                        isLoading: true,
                        metadata: { name: agent.metadata.name },
                        connected: this.registrations.has(agent.name),
                        lastActivityAtSecs: 0,
                        channel,
                        previousState: State.None,
                        doNotDisturb: false,
                    },
                );
                channel.join(joinTimeout).receive('error', (err) => {
                    this.$store.dispatch('isLoadingAgent', updateLoadingAgent);
                    this.logger.captureError(err);
                }).receive('timeout', () => {
                    this.$store.dispatch('isLoadingAgent', updateLoadingAgent);
                });
            });
        });

        agentsChannel.on('update_registrations', (data) => {
            this.registrations = new Map<string, Registration>(Object.entries(data.registrations));
        });

        agentsChannel.join(joinTimeout).receive('error', (response) => {
            this.logger.captureError(response);
            console.error('Unable to join on agents:all', response);
        });
    }

    private setAgentsToShow(agents: any) {
        const minBotNumber = 6000;
        const maxBotNumber = 7999;

        this.agentsToShow = agents.filter((agent: any) => {
            const numberPart = parseInt(agent.name.split('@')[0], 10);
            return numberPart >= minBotNumber && numberPart <= maxBotNumber;
        });
    }

    private setInitialAgentsOnScreen() {
        this.selectedAgents = this.agentsToShow;
    }

    private selectAgents(agents: any) {
        this.selectedAgents = agents;

        this.callsInformation = [];

        agents.forEach((agent: any) => {
            const agentCallState = this.$store.getters.agentCallStates(agent.name);

            if (agentCallState) {
                this.callsInformation = this.callsInformation.concat(agentCallState);
            }
        });
    }

    private refresh() {
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.isCounting = false;
        }
        this.countdown = 5;
        this.selectAgents(this.selectedAgents);
        this.startCountdown();
    }
}
