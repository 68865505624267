













































































































































































import Dropdown, { DropdownItem } from '@/components/Dropdown.vue';
import Icon from '@/components/Icon.vue';
import Call from '@/components/queue/Call.vue';
import ModalAttachQueue from '@/components/agent/ModalAttachQueue.vue';
import ModalUpdateAgent from '@/components/agent/ModalUpdateAgent.vue';
import Tooltip from '@/components/Tooltip.vue';
import capitalize from '@/utils/text';
import idealName from '@/utils/ideal-name';
import { BridgeAgent, Tier } from '@/store/modules/agent';
import {
    AgentCustomStatus,
    AgentConduct,
} from '@/utils/agent';
import {
    TierState, State,
} from '@/utils/tier/state';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Chronometer from '@/atomics/Chronometer.vue';
import { LocaleMessages } from 'vue-i18n';

@Component({
    components: {
        Dropdown,
        Icon,
        ModalAttachQueue,
        ModalUpdateAgent,
        Chronometer,
        Call,
        Tooltip,
    },
})
export default class Agent extends Vue {
    @Prop(Boolean) readonly moreDetail!: boolean;

    @Prop(Boolean) readonly ringing!: boolean;

    @Prop(Boolean) readonly connected!: boolean;

    @Prop(Boolean) readonly show!: boolean;

    @Prop(Boolean) readonly idleTimeHidden!: boolean;

    @Prop(String) readonly queue!: string;

    @Prop(String) readonly name!: string;

    @Prop(String) readonly memberUuid!: string;

    @Prop(Array) readonly queues!: Array<string>;

    idealName = idealName;

    capitalize = capitalize;

    tierState: TierState = TierState;

    get dropdownItemsAgent(): Array<DropdownItem> {
        return [
            {
                name: this.$t('assign_queue'),
                action: () => { (this.$refs.modalAttachQueue as ModalAttachQueue).toogleModal(); },
            },
            {
                name: this.$t('edit_agent'),
                action: () => { (this.$refs.modalUpdateAgent as ModalUpdateAgent).toogleModal(); },
            },
            {
                name: this.$t('hangup_call'),
                action: () => {
                    const call = this.$store.getters.getFirstCallIdByAgent(this.name);
                    if (call !== '') {
                        this.$store.dispatch('hangupCall', { name: this.name, call });
                    }
                },
            },
            {
                name: this.$t('dettach_queue'),
                action: () => {
                    this.$store.dispatch('dettachQueue', { name: this.name, queue: this.queue });
                },
            },
            {
                name: this.agent.doNotDisturb ? this.$t('do_available') : this.$t('do_not_disturb'),
                action: () => {
                    const actionToMake = this.agent.doNotDisturb
                        ? 'disableAgentDoNotDisturb'
                        : 'enableAgentDoNotDisturb';

                    this.$store.dispatch(actionToMake, this.name);
                },
            },
            {
                name: TierState.isPaused(this.agentAsTierInQueue.state)
                    ? this.$t('agent.state.ready')
                    : this.$t('agent.state.absent'),
                action: () => {
                    this.$store.dispatch('updateAgentState', {
                        queueName: this.queue,
                        agentName: this.name,
                        state: TierState.isPaused(this.agentAsTierInQueue.state)
                            ? State.Ready
                            : State.Absent,
                    });
                },
            },
        ];
    }

    get agentAsTierInQueue(): Tier {
        return this.$store.getters.queueTiers(this.queue).find((tier: Tier) => tier.agent === this.name);
    }

    get isInactive(): boolean {
        return this.getQueueCalls.length <= 0
            && this.getCallStates.length <= 0
            && this.memberUuid === '';
    }

    get agentCustomStatus(): AgentCustomStatus {
        const agentConduct: AgentConduct = {
            connected: this.connected && !this.agent.isLoading,
            active: !this.isInactive,
        };
        return AgentCustomStatus.fromPanel(
            this.agent.status,
            this.agent.state,
            agentConduct,
            this.agent.previousState,
        );
    }

    get customStatusTranslation(): string | LocaleMessages {
        return this.agent.doNotDisturb
            ? this.$t('dnd')
            : this.$t(this.agentCustomStatus.translation());
    }

    get agent() {
        return this.$store.getters.getAgentByName(this.name);
    }

    get memberCall() {
        return this.$store.getters.queueMemberCall(this.queue, this.memberUuid);
    }

    get agentName(): string {
        return this.$store.getters.agentName(this.agent.name);
    }

    get agentNameExtension(): string {
        return this.$store.getters.agentExtensionName(this.agent.name);
    }

    get tooltipName(): string {
        if (this.agentName !== this.agentNameExtension) {
            return `(${this.agentNameExtension}) ${this.agentName}`;
        }
        return this.agentName;
    }

    get getQueueCalls() {
        return Array.from(this.agent.queueCalls?.values() ?? [])
            .filter((call) => (call as BridgeAgent).queue === this.queue);
    }

    get getCallStates() {
        return this.$store.getters.agentCallStates(this.name);
    }
}
