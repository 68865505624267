


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';
import { LocaleMessages } from 'vue-i18n';

export interface SelectOption {
    name: string;
    value: string;
}

@Component({
    components: { Icon },
})
export default class Select extends Vue {
    @Prop(String) readonly label!: string;

    @Prop(Boolean) readonly top!: boolean;

    @Prop(Boolean) readonly multiple!: boolean;

    @Prop({ default: () => [] }) readonly options!: Array<SelectOption>;

    @Prop({ default: () => [] }) readonly preSelectedOptions!: Array<SelectOption>;

    selected = new Set<string>();

    isOpen = false;

    selectedName: string | LocaleMessages = '';

    created() {
        this.preSelectedOptions.forEach((option: SelectOption) => {
            this.selectOption(option);
        });

        this.setSelectedName(this.selected);
    }

    selectOption(option: SelectOption) {
        if (this.selected.has(option.name)) {
            this.selected.delete(option.name);
        } else {
            if (!this.multiple) this.selected = new Set();
            this.selected.add(option.name);
        }
        this.setSelectedName(this.selected);
        this.$emit('change', this.values());
    }

    clickedOutside() {
        this.isOpen = false;
    }

    private setSelectedName(selected: Set<string>) {
        const name = Array.from(selected, (item) => item).join(', ');
        this.selectedName = name === '' ? this.$t('nothing_selected') : name;
    }

    private values() {
        return this.options.reduce((acc, option: SelectOption) => {
            if (this.selected.has(option.name)) {
                acc.push(option.value);
            }
            return acc;
        }, new Array<string>());
    }
}
