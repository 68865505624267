import { QueueBasicInfo, VisibilityGroup } from '@/core/queues/Queue';

export default class StorageVisibility {
    private prefix: string;

    private visibilityGroupKey: string

    private constructor(prefix: string) {
        this.prefix = prefix;
        this.visibilityGroupKey = `${this.prefix}-visibility-group`;
    }

    public static create(prefix: string): StorageVisibility {
        return new StorageVisibility(prefix);
    }

    public storageQueueVisible(queueName: string, queueVisible: boolean): void {
        window.localStorage.setItem(this.getQueueNameWithStorageNamespace(queueName), JSON.stringify(queueVisible));
    }

    public storageQueueAsVisible(queueName: string): void {
        window.localStorage.setItem(this.getQueueNameWithStorageNamespace(queueName), JSON.stringify(true));
    }

    public storageQueueAsNotVisible(queueName: string): void {
        window.localStorage.setItem(this.getQueueNameWithStorageNamespace(queueName), JSON.stringify(false));
    }

    public getQueueVisibility(queueName: string): boolean {
        const queueVisibility = window.localStorage.getItem(this.getQueueNameWithStorageNamespace(queueName));

        return queueVisibility
            ? JSON.parse(queueVisibility)
            : true;
    }

    public storageVisibilityGroup(name: string, queues: QueueBasicInfo[]): void {
        const currentVisibilityGroups: VisibilityGroup[] = this.getVisibilityGroups();

        currentVisibilityGroups.push({
            name,
            queues,
        });

        this.saveVisibilityGroups(currentVisibilityGroups);
    }

    public removeVisibilityGroup(name: string): void {
        const currentVisibilityGroups: VisibilityGroup[] = this.getVisibilityGroups();

        const groupIndex = currentVisibilityGroups.findIndex((group) => group.name === name);

        if (groupIndex === -1) return;

        currentVisibilityGroups.splice(groupIndex, 1);

        this.saveVisibilityGroups(currentVisibilityGroups);
    }

    public getVisibilityGroups(): VisibilityGroup[] {
        const groupsInLocalStorage = window.localStorage.getItem(this.visibilityGroupKey);

        return groupsInLocalStorage
            ? JSON.parse(groupsInLocalStorage)
            : [];
    }

    private getQueueNameWithStorageNamespace(queueName: string): string {
        return `${this.prefix}-queue-visibility-${queueName}`;
    }

    private saveVisibilityGroups(visibilityGroups: VisibilityGroup[]): void {
        window.localStorage.setItem(this.visibilityGroupKey, JSON.stringify(visibilityGroups));
    }
}
