import { ChronometerClockObserver, divideTime } from '@/utils/time';

interface ChronometerManagerItem {
    id: string;

    now: number;

    elapsedMs: number;

    observers: [ChronometerClockObserver];
}

type Clocks = { [key: string]: ChronometerManagerItem };

export class ChronometerClockManager {
    private clocks: Clocks = {};

    constructor() {
        setInterval(() => {
            Object.keys(this.clocks).forEach((kid) => {
                // TEL-1181 best way to get a real second, when browser have low cpu cycles?
                const now = Date.now();
                const elapsedMs = now - this.clocks[kid].now;
                this.clocks[kid].now = now;

                this.clocks[kid].elapsedMs += elapsedMs;
            });
        }, 1000);

        setInterval(() => {
            Object.values(this.clocks).forEach((clock) => {
                const elapsedSecs = Math.round(clock.elapsedMs / 1000);
                const minSec = divideTime(elapsedSecs);
                this.callObservers(clock.id, minSec.right, minSec.left);
            });
        }, 800);
    }

    addClock(kid: string, startFromSecs: number, observer: ChronometerClockObserver) {
        if (!this.clocks[kid]) {
            const nowAt = Date.now();
            const start = Math.round(startFromSecs * 1000);
            this.clocks[kid] = {
                id: kid, now: nowAt, elapsedMs: start, observers: [observer],
            };
        } else {
            this.clocks[kid].observers.push(observer);
        }
    }

    removeClock(id: string) {
        if (!this.clocks[id]) {
            return;
        }

        // remove a observer
        this.clocks[id].observers.pop();

        // stop clock when no more observers
        if (Number(this.clocks[id].observers.length) === 0) {
            delete this.clocks[id];

            console.debug(`stopped chronometer id: ${id}`);
        }
    }

    resetClock(id: string, startFromSecs = 0): void {
        if (!this.clocks[id]) {
            return;
        }
        this.clocks[id].elapsedMs = Math.round(startFromSecs * 1000);
    }

    private callObservers(id: string, seconds: number, minutes: number) {
        this.clocks[id].observers.forEach((observer: ChronometerClockObserver) => {
            observer(seconds, minutes);
        });
    }
}

export const ChronometerClockManagerInstance = new ChronometerClockManager();
