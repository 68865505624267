import { Channel } from 'phoenix';
import WebSocket from '@/socket';
import { Store } from 'vuex';
import { PanelState } from '@/store/state';

export default function createAgentChannel(
    name: string,
    socket: WebSocket,
    store: Store<PanelState>,
    onInit: () => void,
): Channel {
    const channel = socket.channel(`agent:${name}`);

    channel.on('agent_init', (data) => {
        store.dispatch('updateTiers', {
            tiers: data.tiers,
            name,
        });

        store.dispatch('updateQueueCalls', {
            queueCalls: data.queue_calls,
            name,
        });

        store.dispatch('updateCallStates', {
            callStates: data.call_states,
            name,
        });

        store.dispatch('updateLastActivityAtSecs', {
            lastActivityAtSecs: data.last_activity_at_secs,
            name,
        });

        onInit();
    });

    channel.on('agent_state_change', (data) => {
        store.dispatch('changeAgentState', {
            agentState: data.agent_state,
            name,
        });
    });

    channel.on('tier_added', (data) => {
        store.dispatch('updateTiers', {
            tiers: data.tiers,
            name,
        });
    });

    channel.on('tier_removed', (data) => {
        store.dispatch('updateTiers', {
            tiers: data.tiers,
            name,
        });
    });

    channel.on('bridge_agent_start', (data) => {
        store.dispatch('updateQueueCalls', {
            queueCalls: data.queue_calls,
            name,
        });
    });

    channel.on('bridge_agent_end', (data) => {
        store.dispatch('updateQueueCalls', {
            queueCalls: data.queue_calls,
            name,
        });
    });

    channel.on('call_states', (data) => {
        store.dispatch('updateCallStates', {
            callStates: data.call_states,
            name,
        });
    });

    channel.on('update_registration', (data) => {
        store.dispatch('updateAgentRegistration', {
            connected: data.registered,
            name,
        });
    });

    channel.on('refresh_last_activity', (data) => {
        store.dispatch('updateLastActivityAtSecs', {
            lastActivityAtSecs: data.last_activity_at_secs,
            name,
        });
    });

    channel.on('do_not_disturb_changed', (data) => {
        store.dispatch('putAgentInDoNotDisturb', {
            state: data.state,
            status: data.status,
            name,
        });
    });

    channel.on('agent_status_change', (data) => {
        store.dispatch('changeAgentStatus', {
            agentStatus: data.agent_status,
            name,
        });
    });

    return channel;
}
