

















import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

@Component({ components: { Icon } })
export default class Stat extends Vue {
    @Prop(String) readonly name!: string;

    @Prop(String) readonly stat!: string;

    @Prop(String) readonly icon!: string;

    @Prop({ default: 'indigo' }) readonly color!: string;
}
