/* eslint-disable @typescript-eslint/camelcase */
export default {
    internal: 'interno',
    team: 'equipo',
    dettach_queue: 'Desvincular',
    edit: 'Edicion',
    confirm: 'Confirmar',
    edit_agent: 'Editar agente',
    extension_number: 'Numero de extension',
    nothing_selected: 'Nada seleccionado',
    assign_queue: 'Asignar a cola',
    assignament_queue_description: 'Puede asignar al agente {agent} en las siguientes colas:',
    agents: 'Agentes',
    more_detail: 'Mas detalle',
    hangup: 'Colgar',
    hangup_call: 'Colgar llamada',
    ringing: 'Timbrando',
    do_not_disturb: 'Cambiar a no molestar',
    dnd: 'No molestar',
    do_available: 'Cambiar a disponible',
    do_not_show_anymore: 'No mostrar más',
    panel_settings: 'Configuraciones del panel',
    visual_panel_settings: 'Configuracion visual panel',
    waiting_calls: 'Hay al menos 1 llamada en espera en la/s cola/s:',
    waiting_calls_activate: 'Ver notificaciones de llamadas en espera',
    no_answer: 'Sin contestar',
    call: {
        incoming: 'Entrante | Entrantes',
        in_progress: 'En curso',
    },
    agent: {
        form: {
            name: 'Nombre',
            edit_description: 'Aca puede editar algunos atributos del agente {agent}:',
        },
        agent: 'Agente | Agentes',
        status: {
            logged_out: 'No disponible',
            available: 'Disponible',
            available_on_demand: 'Disponible en demanda',
            on_break: 'Descanso',
            not_assign: 'Sin asignar',
        },
        state: {
            idle: 'Ocioso',
            waiting: 'Esperando',
            receiving: 'Recibiendo',
            in_queue_call: 'Atendiendo llamada',
            ready: 'Resumir Agente',
            absent: 'Pausar Agente',
        },
        custom_status: {
            busy: 'Ocupado',
            available: 'Disponible',
            unavailable: 'No disponible',
            agitated: 'Timbrando',
            online: 'En línea',
            break: 'Descanso',
            offline: 'Sin conectar',
        },
    },
    queue: {
        queue: 'Cola | Colas',
        form: {
            name: 'Nombre',
            edit_description: 'Aca puede editar algunos atributos de la cola {queue}:',
        },
    },
    callcenter: {
        abandoned: 'Abandonadas',
        abandoned_rate: 'Tasa de abandono',
        answered: 'Contestadas',
        average_talk_seconds: 'Promedio charla',
        average_wait_seconds: 'Promedio espera',
        logins: 'Inicio sesion',
        logoff: 'Desconectados',
        max_wait_time_seconds: 'Max. espera',
        received: 'Admitido',
        unanswered: 'Sin respuesta',
    },
};
