











































import { Component, Vue, Prop } from 'vue-property-decorator';
import idealName from '@/utils/ideal-name';
import capitalize from '@/utils/text';
import IndicatorQueue from '@/atomics/IndicatorQueue.vue';
import { AgentStatus } from '@/utils/agent/index';
import { Tier } from '@/store/modules/agent';
import Icon from '@/components/Icon.vue';

@Component({ components: { IndicatorQueue, Icon } })
export default class QueueChunk extends Vue {
    @Prop(String) readonly name!: string;

    @Prop(Boolean) readonly expanded!: boolean;

    get queue() {
        return this.$store.getters.getQueueByName(this.name);
    }

    get queueName() {
        return this.queue.metadata?.name ?? capitalize(idealName(this.name));
    }

    get visible() {
        return this.$store.getters.isVisibleQueue(this.name);
    }

    switchVisibility() {
        this.$store.dispatch('changeQueueVisibility', { name: this.name, visible: !this.visible });
    }

    countAvailableAgents(): number {
        return this.queue.tiers.reduce((acc: number, tier: Tier) => {
            const agent = this.$store.getters.getAgentByName(tier.agent);
            let sum = acc;
            if (AgentStatus.isAvailable(agent.status)) {
                sum += 1;
            }
            return sum;
        }, 0);
    }
}
