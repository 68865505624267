export function divideTime(unit: number): {left: number; right: number} {
    const divide = unit / 60;
    const left = Math.trunc(divide);
    const right = Math.floor((divide - left) * 60);
    return { left, right };
}

export function incrementTime(unit: number): number {
    if (unit > 60) return 0;
    return unit + 1;
}

export function unitToString(unit: number): string {
    return unit < 10 ? `0${unit}` : `${unit}`;
}

export interface ChronometerClockObserver {
    (seconds: number, minutes: number): void;
}
