



























import {
    Component,
    Vue,
    Prop,
    VModel,
    Ref,
} from 'vue-property-decorator';
import { Validation } from '@/utils/validator';

@Component({})
export default class InputGroup extends Vue {
    @VModel({ type: String }) name!: string;

    @Prop({ default: '' }) readonly inputName!: string;

    @Prop({ default: '' }) readonly label!: string;

    @Prop({ default: '' }) readonly type!: string;

    @Prop({ default: false }) readonly required!: boolean;

    @Prop({ default: () => ([]) }) readonly validations!: Array<(value: string) => Validation>;

    @Ref() readonly input!: HTMLInputElement;

    inputNormalClass = 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md'
    + ' shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500'
    + ' focus:border-gray-500 sm:text-sm'

    inputErrorClass = 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300'
    + ' focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'

    isValid = true;

    get validation(): Validation {
        const validation = this.validations.reduce(
            (acc: Validation, method: (value: string) => Validation) => {
                if (!acc.valid) return acc;
                const val = method(this.name);
                return val;
            },
            { message: '', valid: true },
        );

        if (this.input) {
            this.input.setCustomValidity(validation.message);
        }
        return validation;
    }
}
