



































import {
    Vue,
    Component,
    Prop,
    Watch,
} from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import Icon from '@/components/Icon.vue';
import { Queue as QueueStore } from '@/store/modules/queue';

@Component({
    components: {
        Draggable,
        Icon,
    },
})
export default class SortQueues extends Vue {
    @Prop(Array) readonly queues!: Array<QueueStore>;

    queuesInCustomOrder: Array<QueueStore> = [];

    @Watch('queues', {
        immediate: true,
    })
    initQueuesInCustomOrder() {
        if (this.queuesInCustomOrder.length > 0) return;

        this.queuesInCustomOrder = [...this.queues];
    }

    @Watch('queuesInCustomOrder', {
        deep: true,
    })
    orderQueues() {
        this.$emit('queuesOrderWasChanged', this.queuesInCustomOrder);
    }
}
