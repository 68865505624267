export type FiltersForQueues = {
    onlyStats: boolean;
    onlyAgents: boolean;
}

export default class Filter {
    private _storageKey: string;

    private _filters: FiltersForQueues = {} as FiltersForQueues;

    constructor(prefix: string, queueName: string) {
        this._storageKey = `${prefix}-filters-by-queue-${queueName}`;

        const filterByQueueInStorage = window.localStorage.getItem(this._storageKey);

        this._filters = filterByQueueInStorage
            ? JSON.parse(filterByQueueInStorage)
            : {
                onlyStats: false,
                onlyAgents: false,
            };
    }

    public static fromQueue(prefix: string, queueName: string): Filter {
        return new Filter(prefix, queueName);
    }

    get filters(): FiltersForQueues {
        return this._filters;
    }

    enableOnlyStats(): void {
        this._filters.onlyStats = true;

        this.storageFilters();
    }

    disableOnlyStats(): void {
        this._filters.onlyStats = false;

        this.storageFilters();
    }

    enableOnlyAgents(): void {
        this._filters.onlyAgents = true;

        this.storageFilters();
    }

    disableOnlyAgents(): void {
        this._filters.onlyAgents = false;

        this.storageFilters();
    }

    private storageFilters(): void {
        window.localStorage.setItem(this._storageKey, JSON.stringify(this._filters));
    }
}
