import {
    AgentStateModule,
    Agent,
    AgentInputs,
} from '@/store/modules/agent/types';
import {
    UpdatedTiers,
    UpdatedCallStates,
    UpdatedQueueCalls,
    ChangedAgentState,
    ChangedAgentStatus,
    UpdatedAgentRegistration,
    UpdateLoadingAgent,
    UpdatedLastActivityAtSecs,
    ChangeAgentDoNotDisturb,
} from '@/store/modules/agent/events';
import { AgentStatus, AgentState } from '@/utils/agent';
import { DoNotDisturb as AgentDoNotDisturb } from '@/utils/agent/doNotDisturb';
import Vue from 'vue';

const mutations = {
    addAgentToContainer(state: AgentStateModule, agent: Agent) {
        Vue.set(state.agentsContainer, agent.name, agent);
    },
    updateTiers(state: AgentStateModule, { name, tiers }: UpdatedTiers) {
        let agent = state.agentsContainer[name];
        agent = Object.assign(agent, { tiers });
        state.agentsContainer = Object.assign(state.agentsContainer, { [name]: agent });
    },
    updateCallStates(state: AgentStateModule, { name, callStates }: UpdatedCallStates) {
        let agent = state.agentsContainer[name];
        agent = Object.assign(agent, { callStates: new Map(Object.entries(callStates)) });
        state.agentsContainer = Object.assign(state.agentsContainer, { [name]: agent });
    },
    updateQueueCalls(state: AgentStateModule, { name, queueCalls }: UpdatedQueueCalls) {
        let agent = state.agentsContainer[name];
        agent = Object.assign(agent, { queueCalls: new Map(Object.entries(queueCalls)) });
        state.agentsContainer = Object.assign(state.agentsContainer, { [name]: agent });
    },
    changeAgentMetadata(state: AgentStateModule, { name, metadata }: AgentInputs) {
        let agent = state.agentsContainer[name];
        agent = Object.assign(agent, { metadata: { ...agent.metadata, ...metadata } });
        state.agentsContainer = Object.assign(state.agentsContainer, { [name]: agent });
    },
    changeAgentState(
        state: AgentStateModule,
        { name, agentState }: ChangedAgentState,
    ) {
        let agent = state.agentsContainer[name];
        agent = Object.assign(agent, { state: AgentState.fromString(agentState), previousState: agent.state });
        state.agentsContainer = Object.assign(state.agentsContainer, { [name]: agent });
    },
    changeAgentStatus(
        state: AgentStateModule,
        { name, agentStatus }: ChangedAgentStatus,
    ) {
        const agent = state.agentsContainer[name];

        state.agentsContainer = Object.assign(state.agentsContainer, {
            [name]: Object.assign(agent, { status: AgentStatus.fromString(agentStatus) }),
        });
    },
    putAgentInDoNotDisturb(
        state: AgentStateModule,
        params: ChangeAgentDoNotDisturb,
    ) {
        const agent = state.agentsContainer[params.name];

        const isBusy = AgentDoNotDisturb.fromString(params.state);

        if (isBusy) {
            state.agentsContainer = Object.assign(state.agentsContainer, {
                [params.name]: Object.assign(
                    agent,
                    { doNotDisturb: isBusy, status: params.status },
                ),
            });
        } else {
            state.agentsContainer = Object.assign(state.agentsContainer, {
                [params.name]: Object.assign(
                    agent,
                    { doNotDisturb: isBusy },
                ),
            });
        }
    },
    updateAgentRegistration(
        state: AgentStateModule,
        { name, connected }: UpdatedAgentRegistration,
    ) {
        const agent = state.agentsContainer[name];
        state.agentsContainer = Object.assign(state.agentsContainer, {
            [name]: Object.assign(agent, { connected }),
        });
    },
    isLoadingAgent(
        state: AgentStateModule,
        { name, isLoading }: UpdateLoadingAgent,
    ) {
        const agent = state.agentsContainer[name];
        state.agentsContainer = Object.assign(state.agentsContainer, {
            [name]: Object.assign(agent, { isLoading }),
        });
    },
    updateLastActivityAtSecs(
        state: AgentStateModule,
        { name, lastActivityAtSecs }: UpdatedLastActivityAtSecs,
    ) {
        const agent = state.agentsContainer[name];
        state.agentsContainer = Object.assign(state.agentsContainer, {
            [name]: Object.assign(agent, { lastActivityAtSecs }),
        });
    },
};
export default mutations;
