import * as Sentry from '@sentry/vue';
import Logger from '@/utils/logger/logger';
import Vue from 'vue';
import { Integrations } from '@sentry/tracing';
import router from '@/router';

export default class SentryLogger implements Logger {
    constructor(vue: typeof Vue, vueRouter: typeof router) {
        if (process.env.NODE_ENV !== 'production') return;

        Sentry.init({
            Vue: vue,
            environment: process.env.NODE_ENV,
            release: process.env.VUE_APP_WEB_VERSION,
            dsn: 'https://1c4870e3bb9d4b8d9a0093eb5f5092f4@o108030.ingest.sentry.io/6591948',
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter),
                    tracingOrigins: ['panel.picallex.com', 'panel.myflexpbx.com', /^\//],
                }),
            ],
            tracesSampleRate: 0.2,
            tracingOptions: {
                trackComponents: true,
            },
            attachProps: true,
            attachStacktrace: true,
            logErrors: true,
        });
    }

    static fromVueInstance(vue: typeof Vue, vueRouter: typeof router): SentryLogger {
        return new SentryLogger(vue, vueRouter);
    }

    // eslint-disable-next-line class-methods-use-this
    captureError(error: Error): void {
        Sentry.captureException(error);
    }
}
