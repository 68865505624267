






























import {
    Vue,
    Prop,
    Component,
} from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

@Component({
    components: {
        Icon,
    },
})
export default class Radio extends Vue {
    @Prop(Boolean) readonly checked!: boolean;

    @Prop(String) readonly title!: string;

    @Prop(String) readonly subtitle!: string | undefined;
}
