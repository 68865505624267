











































import Breadcrum, { BreadcrumItem } from '@/components/Breadcrum.vue';
import Dropdown, { DropdownItem } from '@/components/Dropdown.vue';
import Header from '@/components/Header.vue';
import Icon from '@/components/Icon.vue';
import Sidebar, { MenuLink } from '@/components/Sidebar.vue';
import ModalVersion from '@/components/ModalVersion.vue';
import {
    Component,
    Vue,
    Watch,
    Prop,
} from 'vue-property-decorator';
import UserInfo from '@/core/userinfo';

@Component({
    components: {
        Breadcrum,
        Dropdown,
        Header,
        Icon,
        Sidebar,
        ModalVersion,
    },
})
export default class App extends Vue {
    @Prop(UserInfo) readonly userinfo!: UserInfo;

    breadcrumLevels: Array<BreadcrumItem> = [
        { name: 'Panel', url: '#' },
    ]

    menuLinks: Array<MenuLink> = []

    dropdownItems: Array<DropdownItem> = [
        {
            name: 'Version',
            icon: 'information-circle',
            iconFilled: true,
            action: () => { this.openVersionModal(); },
        },
        {
            name: 'Salir',
            icon: 'logout',
            iconFilled: true,
            action: () => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                this.$auth.logout({
                    returnTo: window.location.origin,
                });
                sessionStorage.setItem('token', '');
            },
        },
    ]

    @Watch('isAuthLoading')
    onIsAuthChanged() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!this.$auth.loading && this.$auth.isAuthenticated) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.$auth.getTokenSilently().then((token) => {
                sessionStorage.setItem('token', token);
            });
        }
    }

    @Watch('userinfo')
    onUserInfoChange() {
        this.setMenuLinks();
    }

    get isAuthLoading() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return this.$auth.loading;
    }

    mounted() {
        this.setMenuLinks();
    }

    setMenuLinks() {
        this.menuLinks = [
            {
                title: 'Panel',
                url: '/panel',
                selected: true,
                icon: 'home',
            },
        ];

        const canSeeCallsByAgent = this.userinfo.customers.some(
            (customer) => customer.domain === 'vip2pbx.myflexpbx.com'
            || customer.domain === 'biomax1.myflexpbx.com'
            || customer.domain === 'biomax2.myflexpbx.com'
            || customer.domain === 'biomax3.myflexpbx.com'
            || customer.domain === 'cloudpbxclient1.picallex.com',
        );

        if (canSeeCallsByAgent) {
            this.addLinkCallsByAgent();
        }
    }

    addLinkCallsByAgent() {
        this.menuLinks.push({
            title: 'Agent',
            url: '/panel/agent',
            selected: false,
            icon: 'bot',
        });
    }

    toogleSidebar() {
        const sidebar = this.$refs.sidebar as Sidebar;
        sidebar.toogleMobile();
    }

    openVersionModal() {
        (this.$refs.modalVersion as ModalVersion).toogle();
    }

    updateSelectedMenu(index: number) {
        this.menuLinks.forEach((menu, i) => {
            // eslint-disable-next-line no-param-reassign
            menu.selected = i === index;
        });
    }
}
