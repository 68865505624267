import HttpClient from '@/api/http-client';

export interface CustomerInfo {
    id: number;
    domain: string;
}

interface UserInfoApi {
    username: string;
    current_domain: string;
    user_settings: {
        customers: Array<CustomerInfo>;
    };
}

export default class UserInfo {
    username: string;

    currentDomain: string;

    customers: Array<CustomerInfo>;

    constructor(username: string, customers: Array<CustomerInfo>, currentDomain: string) {
        this.username = username;
        this.currentDomain = currentDomain;
        this.customers = customers;
    }

    static fromInit(): UserInfo {
        return new UserInfo('NaN', [], '');
    }

    static async fromApi(http: HttpClient): Promise<UserInfo> {
        const options = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        };

        const response = await http.get('/userinfo', {}, options);

        const data = (response.data as UserInfoApi);
        return new UserInfo(data.username, data.user_settings.customers, data.current_domain);
    }
}
