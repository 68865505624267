import { State, AgentState } from '@/utils/agent/state';
import { Status, AgentStatus } from '@/utils/agent/status';

export enum CustomStatus {
    Available = 'available',
    Unavailable = 'unavailable',
    Break = 'break',
    Busy = 'busy',
    Agitated = 'agitated',
    Offline = 'offline',
}

export interface AgentConduct {
    connected: boolean;
    active: boolean;
}

export class AgentCustomStatus {
    private customStatus: CustomStatus;

    private agentConduct: AgentConduct;

    constructor(customStatus: CustomStatus, agentConduct: AgentConduct) {
        this.customStatus = customStatus;
        this.agentConduct = agentConduct;
    }

    static fromAgent(status: Status, state: State, previousState: State): AgentCustomStatus {
        return this.fromPanel(status, state, { connected: false, active: false }, previousState);
    }

    static fromPanel(
        status: Status,
        state: State,
        agentConduct: AgentConduct,
        previousState: State,
    ): AgentCustomStatus {
        if (AgentState.isInQueueCall(state, previousState)) {
            return new AgentCustomStatus(CustomStatus.Busy, agentConduct);
        }
        if (AgentState.isReceiving(state)) {
            return new AgentCustomStatus(CustomStatus.Agitated, agentConduct);
        }
        if (AgentStatus.isOnBreak(status)) {
            return new AgentCustomStatus(CustomStatus.Break, agentConduct);
        }
        if (AgentStatus.isAvailable(status) || AgentStatus.isAvailableOnDemand(status)) {
            return new AgentCustomStatus(CustomStatus.Available, agentConduct);
        }
        return new AgentCustomStatus(CustomStatus.Unavailable, agentConduct);
    }

    statusClass(): string {
        if (!this.agentConduct.connected) return 'text-gray-900 bg-gray-200';

        switch (this.customStatus) {
        case CustomStatus.Busy:
            return 'text-red-800 bg-red-100';
        case CustomStatus.Available:
            if (this.agentConduct.active) return 'text-red-800 bg-red-100';
            return 'text-green-800 bg-green-100';
        case CustomStatus.Agitated:
            return 'text-yellow-800 bg-yellow-100';
        case CustomStatus.Break:
        case CustomStatus.Unavailable:
            return 'text-blue-500 bg-blue-100';
        default:
            if (this.agentConduct.active) return 'text-red-800 bg-red-100';
            return 'text-gray-900 bg-gray-100';
        }
    }

    statusIconClass(): string {
        if (!this.agentConduct.connected) return 'text-gray-500';

        switch (this.customStatus) {
        case CustomStatus.Busy:
            return 'text-red-500';
        case CustomStatus.Available:
            if (this.agentConduct.active) return 'text-red-500';
            return 'text-green-500';
        case CustomStatus.Agitated:
            return 'text-yellow-500';
        case CustomStatus.Break:
        case CustomStatus.Unavailable:
            return 'text-blue-500';
        default:
            if (this.agentConduct.active) return 'text-red-500';
            return 'text-gray-500';
        }
    }

    status(): CustomStatus {
        return this.customStatus;
    }

    translation(): string {
        if (this.agentConduct.connected) {
            if (this.agentConduct.active && this.status() !== CustomStatus.Agitated) return 'agent.custom_status.busy';
            return `agent.custom_status.${this.status()}`;
        }
        return 'agent.custom_status.offline';
    }
}
