


































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Toogle extends Vue {
    @Prop(Boolean) readonly checked!: boolean;

    @Prop({ default: '' }) readonly title!: string;

    @Prop({ default: '' }) readonly testKey!: string;
}
