




































import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';
import idealName from '@/utils/ideal-name';
import capitalize from '@/utils/text';
import InputGroup from '@/components/form/InputGroup.vue';
import { QueueMetadata } from '@/store/modules/queue';

@Component({ components: { Modal, Icon, InputGroup } })
export default class ModalUpdateQueue extends Vue {
    @Prop(String) readonly name!: string;

    isStored = true;

    lastStoredMetadata = { name: '' }

    toogleModal() {
        if (!this.isStored) {
            this.$store.dispatch('changeQueueMetadata', { name: this.name, metadata: this.lastStoredMetadata });
            this.isStored = true;
        }
        (this.$refs.modal as Modal).toogleModal();
    }

    set queueName(value: string) {
        if (this.isStored) {
            this.isStored = false;
            this.lastStoredMetadata.name = this.queueName || '';
        }
        this.$store.dispatch('changeQueueMetadata', { name: this.name, metadata: { name: value } });
    }

    get queueName() {
        return this.metadata().name;
    }

    get cleanedName() {
        return capitalize(idealName(this.name));
    }

    get queue() {
        return this.$store.getters.getQueueByName(this.name);
    }

    metadata(): QueueMetadata {
        return this.$store.getters.queueMetadata(this.name);
    }

    confirm() {
        this.$store.dispatch('updateQueueMetadata', { name: this.name, metadata: { name: this.queueName } });
        this.isStored = true;
        (this.$refs.modal as Modal).toogleModal();
    }
}
