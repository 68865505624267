export enum State {
    Disabled = 'disabled',
    Enabled = 'enabled',
}

export class DoNotDisturb {
    static fromString(state: string): boolean {
        return state === State.Enabled;
    }
}
