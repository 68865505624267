






























import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';
import { Queue as QueueStore } from '@/store/modules/queue';

@Component({
    components: {
        Icon,
    },
})
export default class QueuesVisible extends Vue {
    @Prop(Array) readonly queues!: Array<QueueStore>;

    @Prop(Boolean) readonly isVisible!: boolean;
}
