

































































import { Component, Vue, Prop } from 'vue-property-decorator';
import FilterCore from '@/core/panel/Filter';
import Toogle from '@/components/form/Toogle.vue';
import Dropdown, { DropdownItem } from '@/components/Dropdown.vue';
import Tooltip from '@/components/Tooltip.vue';
import Icon from '@/components/Icon.vue';
import Button from '@/atomics/Button.vue';

@Component({
    components: {
        Toogle,
        Dropdown,
        Tooltip,
        Icon,
        Button,
    },
})
export default class PanelFilter extends Vue {
    @Prop(FilterCore) readonly filter!: FilterCore;

    @Prop(Number) readonly queuesLength!: number;

    get dropdownItems(): Array<DropdownItem> {
        return [
            {
                name: 'Vista personalizada',
                action: () => {
                    this.filter.disableOnlyStats();
                    this.filter.disableOnlyAgents();

                    this.optionSelectedIndex = 0;
                },
                dataFilter: 'custom-view',
            },
            {
                name: 'Solo agentes',
                action: () => {
                    this.filter.enableOnlyAgents();
                    this.filter.disableOnlyStats();

                    this.optionSelectedIndex = 1;
                },
                dataFilter: 'only-agents',
            },
            {
                name: 'Solo estadisticas',
                action: () => {
                    this.filter.enableOnlyStats();
                    this.filter.disableOnlyAgents();

                    this.optionSelectedIndex = 2;
                },
                dataFilter: 'only-stats',
            },
        ];
    }

    optionSelectedIndex = 0;
}
