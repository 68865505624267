// NOTE: The contents of this file will only be executed if
// you uncomment its entry in "assets/js/app.js".

// To use Phoenix channels, the first step is to import Socket,
// and connect at the socket path in "lib/web/endpoint.ex".
//
// Pass the token on params as below. Or remove it
// from the params if you are not using authentication.
import { Socket, Channel } from 'phoenix';
import Logger from './utils/logger/logger';

export default class WebSocket {
    private socket: Socket;

    constructor(url: string, opts = {}, logger: Logger) {
        this.socket = new Socket(url, opts);
        this.socket.onError((err) => {
            console.log(err);
            logger.captureError(new Error('there was an error with the connection!'));
        });
    }

    channel(name: string, opts = {}): Channel {
        return this.socket.channel(name, opts);
    }

    connect(): void {
        this.socket.connect();
    }

    static fromToken(url: string, token: string, logger: Logger): WebSocket {
        const socket = new WebSocket(url, { params: { token } }, logger);
        socket.connect();
        return socket;
    }

    isConnected(): boolean {
        return this.socket.isConnected();
    }
}
// When you connect, you'll often need to authenticate the client.
// For example, imagine you have an authentication plug, `MyAuth`,
// which authenticates the session and assigns a `:current_user`.
// If the current user exists you can assign the user's token in
// the connection for use in the layout.
//
// In your "lib/web/router.ex":
//
//     pipeline :browser do
//       ...
//       plug MyAuth
//       plug :put_user_token
//     end
//
//     defp put_user_token(conn, _) do
//       if current_user = conn.assigns[:current_user] do
//         token = Phoenix.Token.sign(conn, "user socket", current_user.id)
//         assign(conn, :user_token, token)
//       else
//         conn
//       end
//     end
//
// Now you need to pass this token to JavaScript. You can do so
// inside a script tag in "lib/web/templates/layout/app.html.eex":
//
//     <script>window.userToken = "<%= assigns[:user_token] %>";</script>
//
// You will need to verify the user token in       {:ok, assign(socket, :user, user_id)}
//         {:error, reason} ->
//           :error
//       end
//     end
//
// Finally, connect to the socket:
//

// Now that you are connected, you can join channels with a topic:
