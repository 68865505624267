import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/i18n';
import vClickOutside from 'v-click-outside';
import { jwtVerify, createRemoteJWKSet } from 'jose';
import App from './App.vue';
import router from './router';
import store from './store';
import HttpClient from './api/http-client';
import { Auth0Plugin } from './auth';
import './assets/styles/index.css';
import SentryLogger from './utils/logger/sentry';

Vue.use(VueI18n);
Vue.config.productionTip = false;
Vue.use(vClickOutside);

const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages,
});

Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    scope: process.env.VUE_APP_AUTH0_SCOPE,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const JWKS = createRemoteJWKSet(new URL(process.env.VUE_APP_AUTH0_JWKS));

        if (sessionStorage.getItem('token')) {
            try {
                const { payload } = await jwtVerify(
                    sessionStorage.getItem('token') || '',
                    JWKS,
                    {
                        issuer: process.env.VUE_APP_AUTH0_ISSUER,
                        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
                    },
                );
                const scopes = (payload.scope as string).split(' ').sort().join(' ');
                const envScopes = process.env.VUE_APP_AUTH0_SCOPE.split(' ').sort().join(' ');
                if (envScopes === scopes) {
                    next();
                } else {
                    next({ path: '/unauthorized', query: { redirect: to.fullPath } });
                }
            } catch (e) {
                console.error(e);
                next({ path: '/unauthorized', query: { redirect: to.fullPath } });
            }
        } else {
            next({
                path: '/',
                query: { redirect: to.fullPath },
            });
        }
    } else {
        next();
    }
});

const logger = SentryLogger.fromVueInstance(Vue, router);

new Vue({
    i18n,
    provide: {
        httpClient: new HttpClient(logger),
        logger,
    },
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
