import getters from '@/store/modules/queue/getters';
import actions from '@/store/modules/queue/actions';
import mutations from '@/store/modules/queue/mutations';
import state from '@/store/modules/queue/state';
import { Module } from 'vuex';
import { QueueStateModule } from '@/store/modules/queue/types';
import { PanelState } from '@/store/state';

const QueueModule = (): Module<QueueStateModule, PanelState> => ({
    state: state(),
    getters,
    mutations,
    actions,
});

export * from '@/store/modules/queue/types';
export default QueueModule;
