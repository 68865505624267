





































import {
    Vue,
    Component,
    Prop,
    Watch,
} from 'vue-property-decorator';

import { QueueStats as QueueStatData } from '@/store/modules/queue/types';
import { divideTime, unitToString } from '@/utils/time';
import Icon from '@/components/Icon.vue';
import Stat from '@/components/queue/Stat.vue';
import Tooltip from '@/components/Tooltip.vue';

@Component({ components: { Icon, Stat, Tooltip } })
export default class QueueStats extends Vue {
    @Prop(Object) readonly stats!: QueueStatData;

    @Prop(Boolean) readonly visible!: boolean;

    @Prop(Number) readonly busyCount!: number;

    @Prop(Number) readonly logoffCount!: number;

    @Prop(Number) readonly onlineCount!: number;

    @Prop(Number) readonly inProgressCount!: number;

    @Prop(Number) readonly waitingCount!: number;

    excludedItems = [
        'customer_id', 'callcenter_queue', 'unanswered',
        'callcenter_queue_id', 'customer', 'logins', 'logoff', 'received',
    ];

    timeItems = ['average_talk_seconds', 'average_wait_seconds', 'max_wait_time_seconds'];

    percentItems = ['abandoned_rate'];

    iconItems = new Map(Object.entries({
        answered: 'check',
        abandoned: 'phone-missed-call',
        unanswered: 'x',
    }));

    sortedItems = new Set<string>(['unanswered', 'answered']);

    get filteredStats(): (string | Map<string, string>)[][] {
        return Object.entries(this.stats).filter(([key]) => !this.exclude(key)).map(([key, value]) => {
            const data = new Map<string, string>();
            data.set('icon', this.iconItems.get(key) ?? '');

            if (this.timeItems.includes(key)) {
                const { left, right } = divideTime(value);
                data.set('data', `${unitToString(left)}:${unitToString(right)}`);
                return [key, data];
            }

            if (this.percentItems.includes(key)) {
                const percent = `${value}%`;
                data.set('data', percent);
                return [key, data];
            }

            data.set('data', `${value}`);
            return [key, data];
        }).sort(([key]) => {
            if (this.sortedItems.has(key as string)) {
                return -1;
            }
            return 0;
        });
    }

    exclude(key: string): boolean {
        return this.excludedItems.includes(key);
    }

    getTooltip(key: string): string {
        const abandonedCalls = 'abandoned';

        return key === abandonedCalls
            ? this.$t('no_answer') as string
            : this.$t(`callcenter.${key}`) as string;
    }
}

