

























































































import {
    Vue,
    Component,
    Prop,
    Watch,
} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';
import Setting from '@/core/panel/Setting';
import Toogle from '@/components/form/Toogle.vue';
import UserInfo from '@/core/userinfo';
import { Queue } from '@/store/modules/queue';
import SortQueues from '@/components/panel/SortQueues.vue';

@Component({
    components: {
        Modal,
        Icon,
        Toogle,
        SortQueues,
    },
})
export default class ModalSettings extends Vue {
    @Prop(Setting) readonly setting!: Setting;

    @Prop(UserInfo) readonly userinfo!: UserInfo;

    @Prop(Array) readonly queues!: Array<Queue>;

    queuesInCustomOrder = [] as Array<Queue>;

    isOpen = false;

    isIdleOptionSelected = false;

    isAdvancedStatsSelected = false;

    waitingCallsWarningIsActivated = false;

    protected updated(): void {
        this.getWaitingCallsWarningStatus();
    }

    toogle() {
        (this.$refs.modal as Modal).toogleModal();

        this.isOpen = !this.isOpen;
    }

    toogleIdleTime(): void {
        this.isIdleOptionSelected = !this.isIdleOptionSelected;
    }

    toogleAdvancedStats(): void {
        this.isAdvancedStatsSelected = !this.isAdvancedStatsSelected;
    }

    applyConfiguration(): void {
        this.$emit('queuesOrderWasChanged', this.queuesInCustomOrder);

        if (this.isAdvancedStatsSelected !== this.setting.advancedStats) this.$emit('toogleAdvancedStats');
        if (this.isIdleOptionSelected !== !this.setting.idleTimeHidden) this.$emit('toogleIdleTime');

        this.toogle();
    }

    applyCustomOrderToQueues(queues: Array<Queue>): void {
        this.$set(this, 'queuesInCustomOrder', [...queues]);
    }

    protected getWaitingCallsWarningStatus() {
        const showNotifications = window.localStorage.getItem('showNotifications');

        this.waitingCallsWarningIsActivated = showNotifications !== 'false';
    }

    toggleWaitingCallsWarning() {
        this.waitingCallsWarningIsActivated = !this.waitingCallsWarningIsActivated;
        window.localStorage.setItem('showNotifications', JSON.stringify(this.waitingCallsWarningIsActivated));
    }

    protected get userHasWaitingCallsFunctionality() {
        return this.userinfo.username === 'PWRJORGE'
        || this.userinfo.username === 'ABALUARTE3'
        || this.userinfo.username === 'VQUIJANO1'
        || this.userinfo.username === 'JOVATESTPCX'
        || this.userinfo.username === 'JUANGONZALEZ';
    }

    @Watch('isOpen')
    init() {
        this.isIdleOptionSelected = !this.setting.idleTimeHidden;
        this.isAdvancedStatsSelected = this.setting.advancedStats;

        this.$set(this, 'queuesInCustomOrder', [...this.queues]);
    }
}
