type Filters = {
    connected: boolean;
    moreDetail: boolean;
    onlyStats: boolean;
    onlyAgents: boolean;
}

export default class Filter {
    private _filters: Filters;

    constructor(filters: Filter) {
        this._filters = filters;
    }

    public static fromPanel(): Filter {
        const storedFilter = window.localStorage.getItem('filters');
        const filters = storedFilter ? JSON.parse(storedFilter) : {
            connected: false,
            moreDetail: false,
            onlyStats: false,
            onlyAgents: false,
        };

        return new Filter(filters);
    }

    get connected(): boolean {
        return this._filters.connected;
    }

    get moreDetail(): boolean {
        return this._filters.moreDetail;
    }

    get onlyStats(): boolean {
        return this._filters.onlyStats;
    }

    get onlyAgents(): boolean {
        return this._filters.onlyAgents;
    }

    toogleConnected(): void {
        this._filters.connected = !this._filters.connected;
        this.storeFilters();
    }

    toogleMoreDetail(): void {
        this._filters.moreDetail = !this._filters.moreDetail;
        this.storeFilters();
    }

    toogleOnlyStats(): void {
        this._filters.onlyStats = !this._filters.onlyStats;
        this.storeFilters();
    }

    enableConnected(): void {
        this._filters.connected = true;

        this.storeFilters();
    }

    enableMoreDetail(): void {
        this._filters.moreDetail = true;

        this.storeFilters();
    }

    enableOnlyStats(): void {
        this._filters.onlyStats = true;

        this.storeFilters();
    }

    disableConnected(): void {
        this._filters.connected = false;

        this.storeFilters();
    }

    disableMoreDetail(): void {
        this._filters.moreDetail = false;

        this.storeFilters();
    }

    disableOnlyStats(): void {
        this._filters.onlyStats = false;

        this.storeFilters();
    }

    enableOnlyAgents(): void {
        this._filters.onlyAgents = true;

        this.storeFilters();
    }

    disableOnlyAgents(): void {
        this._filters.onlyAgents = false;

        this.storeFilters();
    }

    private storeFilters(): void {
        window.localStorage.setItem('filters', JSON.stringify(this._filters));
    }
}
