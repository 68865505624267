


























import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';

@Component({ })
export default class CountDownBar extends Vue {
    @Prop(Number) readonly countdown!: number;
}
