


































































































import Agent from '@/components/queue/Agent.vue';
import Icon from '@/components/Icon.vue';
import IndicatorQueue from '@/atomics/IndicatorQueue.vue';
import Vision, { VisionMode } from '@/utils/vision';
import idealName from '@/utils/ideal-name';
import capitalize from '@/utils/text';
import { Tier, Registration } from '@/store/modules/agent';
import { Queue as QueueStore } from '@/store/modules/queue';
import Tooltip from '@/components/Tooltip.vue';
import ModalUpdateQueue from '@/components/queue/ModalUpdateQueue.vue';
import Spinner from '@/atomics/loaders/Spinner.vue';
import Filter from '@/core/panel/Filter';
import Setting from '@/core/panel/Setting';
import QueueStats from '@/components/queue/QueueStats.vue';
import { QueueStats as QueueStatData } from '@/store/modules/queue/types';
import QueuePanelFilter from '@/components/QueuePanelFilter.vue';
import QueuesFiler from '@/core/queues/Filter';

import {
    Vue,
    Component,
    Prop,
    Inject,
    Watch,
} from 'vue-property-decorator';

@Component({
    components: {
        Icon,
        Agent,
        IndicatorQueue,
        Tooltip,
        ModalUpdateQueue,
        Spinner,
        QueueStats,
        QueuePanelFilter,
    },
})
export default class Queue extends Vue {
    @Inject('storagePrefix') storagePrefix!: string;

    @Prop(Map) readonly registrations!: Map<string, Registration>;

    @Prop(Array) readonly queues!: Array<string>;

    @Prop({ default: VisionMode.Minimized }) readonly visionMode!: VisionMode;

    @Prop(Boolean) readonly moreDetail!: boolean;

    @Prop(String) readonly name!: string;

    @Prop(Filter) readonly filter!: Filter;

    queuesFilter: QueuesFiler = {} as QueuesFiler;

    @Prop(Setting) readonly setting!: Setting;

    vision = Vision.new();

    isAlone = false;

    created() {
        this.init();
    }

    get queue(): QueueStore {
        return this.$store.getters.getQueueByName(this.name);
    }

    get queueName(): string {
        return this.queue.metadata.name || capitalize(idealName(this.name));
    }

    get visible(): boolean {
        return this.$store.getters.isVisibleQueue(this.name);
    }

    get isGeneralFilterApplied(): boolean {
        return this.filter.onlyStats || this.filter.connected || this.filter.moreDetail || this.filter.onlyAgents;
    }

    get isQueueStatsVisible(): boolean {
        return (this.isGeneralFilterApplied || !this.queuesFilter.filters.onlyAgents) && !this.filter.onlyAgents;
    }

    get isAgentsVisible(): boolean {
        return !this.queue.isLoading
        && !this.filter.onlyStats
        && (this.isGeneralFilterApplied || !this.queuesFilter.filters.onlyStats);
    }

    foreverAlone() {
        this.isAlone = !this.isAlone;

        if (this.isAlone) {
            this.$store.dispatch('hideAllQueuesExcept', new Set([this.name]));
        } else {
            this.$store.dispatch('showAllQueues');
        }
    }

    get tiers() {
        return this.$store.getters.queueTiers(this.name);
    }

    memberUuid(tier: Tier): string {
        return this.queue.agentOffers.get(tier.agent)?.uuid || '';
    }

    hide() {
        this.$store.dispatch('changeQueueVisibility', { name: this.name, visible: false });
    }

    countOnlineAgents(): number {
        return this.queue.tiers.reduce(
            (acc: number, tier: Tier) => {
                if (!this.$store.getters.existsAgent(tier.agent)) {
                    return acc;
                }

                if (this.registrations.has(tier.agent)) {
                    return acc + 1;
                }
                return acc;
            },
            0,
        );
    }

    countBusyAgents(): number {
        return this.queue.tiers.reduce(
            (acc: number, tier: Tier) => {
                // esta solucion no es la mas optima
                // como podemos llevar a un estado estable la sincronizacion
                // de tier y agentes?
                if (!this.$store.getters.existsAgent(tier.agent)) {
                    return acc;
                }

                if (this.registrations.has(tier.agent) && this.$store.getters.isAgentBusy(tier.agent)) {
                    return acc + 1;
                }
                return acc;
            },
            0,
        );
    }

    get stats(): QueueStatData {
        return this.$store.getters.queueStats(this.name);
    }

    get hasStats(): boolean {
        return this.stats && this.stats.callcenter_queue === this.name && this.setting.advancedStats;
    }

    @Watch('name')
    private init(): void {
        this.queuesFilter = QueuesFiler.fromQueue(this.storagePrefix, this.name);
    }

    @Watch('queue.membersCount', { immediate: true })
    private onMembersCountChange(): void {
        this.$emit('changedWaitingCallsAmount');
    }
}
