import { urlApi } from '@/utils/url';
import Logger from '@/utils/logger/logger';

export interface PanelResponse {
  data: object;
  headers: Headers | Record<string, string>;
  statusCode: number;
  statusText: string;
}

interface RequestOptions {
  headers?: Record<string, string>;
  [key: string]: unknown;
}

interface Request {
  (url: string, data?: Record<string, unknown>, options?: RequestOptions): Promise<PanelResponse>;
}

export default class HttpClient {
  private logger: Logger;

  constructor(logger: Logger) {
      this.logger = logger;
  }

  private handleResponse = async (response: Response): Promise<PanelResponse> => {
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return {
          data,
          headers: response.headers,
          statusCode: response.status,
          statusText: response.statusText,
      };
  };

  private handleError = (error: unknown): never => {
      this.logger.captureError(error as any);
      throw error;
  };

  post: Request = async (path, data = {}, options: RequestOptions = {}) => {
      try {
          const response = await fetch(urlApi + path, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json',
                  ...options.headers,
              },
              ...options,
          });
          return await this.handleResponse(response);
      } catch (err) {
          return this.handleError(err);
      }
  };

  get: Request = async (
      path,
      params: Record<string, unknown> = {},
      options: RequestOptions = {},
  ): Promise<PanelResponse> => {
      try {
          const url = new URL(urlApi + path);
          Object.keys(params).forEach((key) => url.searchParams.append(key, String(params[key])));
          const response = await fetch(url.toString(), {
              method: 'GET',
              headers: { ...options.headers },
              ...options,
          });
          return await this.handleResponse(response);
      } catch (err) {
          return this.handleError(err);
      }
  };

  patch: Request = async (path, data = {}, options: RequestOptions = {}) => {
      try {
          const response = await fetch(urlApi + path, {
              method: 'PATCH',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json',
                  ...options.headers,
              },
              ...options,
          });
          return await this.handleResponse(response);
      } catch (err) {
          return this.handleError(err);
      }
  };
}
