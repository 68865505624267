export enum VisionMode {
    Maximized,
    Intermediate,
    Minimized
}

export default class Vision {
    private _maximized = VisionMode.Maximized;

    private _intermediate = VisionMode.Intermediate;

    private _minimized = VisionMode.Minimized;

    isMaximized(visionMode: VisionMode): boolean {
        return visionMode === this.maximized();
    }

    isMinimized(visionMode: VisionMode): boolean {
        return visionMode === this.minimized();
    }

    isIntermediate(visionMode: VisionMode): boolean {
        return visionMode === this.intermediate();
    }

    intermediate() {
        return this._intermediate; //eslint-disable-line
    }

    minimized() {
        return this._minimized; //eslint-disable-line
    }

    maximized() {
        return this._maximized; //eslint-disable-line
    }

    static new() {
        return new Vision();
    }
}
