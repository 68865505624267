import {
    AgentStateModule,
    BridgeAgent,
    Agent,
    AgentMetadata,
    CallState,
} from '@/store/modules/agent/types';
import { QueueGetters } from '@/store/getters';
import capitalize from '@/utils/text';
import idealName from '@/utils/ideal-name';

const Getters = {
    getAgentByName: (state: AgentStateModule) => (name: string): Agent => state.agentsContainer[name] ?? {} as Agent,
    isAgentAttachedToQueue: (state: AgentStateModule) => (agentName: string, queue: string) => {
        const agent = state.agentsContainer[agentName];
        return agent?.tiers.findIndex((el) => el.queue === queue) > -1;
    },
    getFirstQueueCallIdByAgent: (state: AgentStateModule) => (name: string): string => {
        const agent = state.agentsContainer[name];
        const queueCall: BridgeAgent = agent.queueCalls.values().next().value;
        if (queueCall) {
            return queueCall.call;
        }
        return '';
    },
    getFirstCallIdByAgent: (state: AgentStateModule) => (name: string): string => {
        const agent = state.agentsContainer[name];
        const callState: CallState = agent.callStates.values().next().value;
        const queueCall: BridgeAgent = agent.queueCalls.values().next().value;

        if (callState) return callState.call_uuid;

        if (queueCall) return queueCall.call;

        return '';
    },
    agentMetadata: (state: AgentStateModule) => (agentName: string): AgentMetadata => {
        const agent: Agent = state.agentsContainer[agentName];
        return agent?.metadata ?? {};
    },
    agentCallStates: (state: AgentStateModule, getters: QueueGetters) => (agentName: string): Array<CallState> => {
        const agent: Agent = state.agentsContainer[agentName];

        if (!agent) return [];

        return Array.from(agent.callStates.values())
            .filter((callState: CallState) => !(agent.queueCalls.has(callState.uuid)
             || (getters.queueNames().some((queue: string) => getters.queueOfferAgents(queue).has(callState.agent)
                 && callState.state === 'ringing' && callState.direction === 'inbound'))));
    },
    agentName: (state: AgentStateModule) => (agentName: string): string => {
        const agent: Agent = state.agentsContainer[agentName];

        return agent?.metadata.name || capitalize(idealName(agentName));
    },
    agentExtensionName: () => (agentName: string): string => capitalize(idealName(agentName)),
    agentConnected: (state: AgentStateModule) => (agentName: string): boolean => {
        const agent: Agent = state.agentsContainer[agentName];
        return agent.connected;
    },
    isAgentBusy: (state: AgentStateModule) => (agentName: string): boolean => {
        const agent: Agent = state.agentsContainer[agentName];
        return agent.queueCalls.size > 0 || agent.callStates.size > 0;
    },
    existsAgent: (state: AgentStateModule) => (agentName: string): boolean => {
        const agent: Agent = state.agentsContainer[agentName];
        return agent !== undefined;
    },
};
export default Getters;
