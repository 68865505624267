export default function capitalize(text: string): string {
    if (text === '') return '';
    return text[0].toUpperCase() + text.slice(1);
}

export const toCamelCase = (text: string): string => text.replace(
    /(?:^\w|[A-Z]|\b\w)/g, (word, index) => (!index
        ? word.toLowerCase()
        : word.toUpperCase()),
).replace(/\s+/g, '');
