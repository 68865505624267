import {
    UpdatedQueueCallsAnswered,
    UpdatedQueueMembersCount,
    UpdatedQueueAgentOffers,
    UpdatedQueueTiers,
    UpdatedQueueMemberCalls,
    UpdatedQueueMetadata,
    ChangedQueueVisibility,
    UpdateLoadingQueue,
    UpdatedQueueStats,
    UpdateAgentState,
    StorageVisibilityGroupParams,
} from '@/store/modules/queue/events';
import { ActionContext } from 'vuex';
import { PanelState } from '@/store/state';
import { Queue, QueueStateModule } from '@/store/modules/queue/types';
import {
    State as TierState,
} from '@/utils/tier/state';

const actions = {
    addQueueToContainer({ commit }: ActionContext<QueueStateModule, PanelState>, queue: Queue) {
        commit('addQueueToContainer', queue);
    },
    updateQueueCallsAnswered(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        queueCallsAnswered: UpdatedQueueCallsAnswered,
    ) {
        commit('updateQueueCallsAnswered', queueCallsAnswered);
    },
    updateQueueAgentOffers(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        queueAgentOffers: UpdatedQueueAgentOffers,
    ) {
        commit('updateQueueAgentOffers', queueAgentOffers);
    },
    updateQueueTiers({ commit }: ActionContext<QueueStateModule, PanelState>, queueTiers: UpdatedQueueTiers) {
        commit('updateQueueTiers', queueTiers);
    },
    updateQueueMemberCalls(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        queueMemberCalls: UpdatedQueueMemberCalls,
    ) {
        commit('updateQueueMemberCalls', queueMemberCalls);
    },
    updateQueueMembersCount(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        queueMembersCount: UpdatedQueueMembersCount,
    ) {
        commit('updateQueueMembersCount', queueMembersCount);
    },
    changeQueueVisibility(
        { commit, getters }: ActionContext<QueueStateModule, PanelState>,
        changedVisible: ChangedQueueVisibility,
    ) {
        commit('changeQueueVisibility', changedVisible);

        getters.storageVisibility.storageQueueVisible(changedVisible.name, changedVisible.visible);
    },
    hideAllQueuesExcept(
        { commit, getters, state }: ActionContext<QueueStateModule, PanelState>, exceptions: Set<string>,
    ) {
        Object.keys(state.queuesContainer).forEach((queueName) => {
            if (!exceptions.has(queueName)) {
                commit('changeQueueVisibility', { name: queueName, visible: false });

                getters.storageVisibility.storageQueueAsNotVisible(queueName);
            }
        });
    },
    showAllQueues({ commit, getters, state }: ActionContext<QueueStateModule, PanelState>) {
        Object.keys(state.queuesContainer).forEach((queueName) => {
            commit('changeQueueVisibility', { name: queueName, visible: true });

            getters.storageVisibility.storageQueueAsVisible(queueName);
        });
    },
    changeQueueMetadata(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        updatedQueueMetadata: UpdatedQueueMetadata,
    ) {
        commit('changeQueueMetadata', updatedQueueMetadata);
    },
    updateQueueMetadata(
        { state }: ActionContext<QueueStateModule, PanelState>,
        updatedQueueMetadata: UpdatedQueueMetadata,
    ) {
        const queue = state.queuesContainer[updatedQueueMetadata.name];
        const attrs = { name: queue.metadata.name };
        queue.channel.push('update_queue', { queue: attrs });
    },
    isLoadingQueue(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        updateLoadingQueue: UpdateLoadingQueue,
    ) {
        commit('isLoadingQueue', updateLoadingQueue);
    },
    updateQueueStats(
        { commit }: ActionContext<QueueStateModule, PanelState>,
        updatedQueueStats: UpdatedQueueStats,
    ) {
        commit('updateQueueStats', updatedQueueStats);
    },

    updateAgentState(
        { state, commit }: ActionContext<QueueStateModule, PanelState>,
        data: UpdateAgentState,
    ) {
        const stateToUpdate = data.state === TierState.Absent
            ? 'pause_agent'
            : 'resume_agent';

        const queue = state.queuesContainer[data.queueName];

        queue.channel.push(stateToUpdate, { agent: data.agentName })
            .receive('ok', () => { commit('updateAgentState', data); })
            .receive('error', (msg) => { throw new Error(`error in update agent state: ${msg}`); });
    },

    storageVisibilityGroup(
        { getters }: ActionContext<QueueStateModule, PanelState>,
        data: StorageVisibilityGroupParams,
    ): void {
        getters.storageVisibility.storageVisibilityGroup(data.name, data.queues);
    },

    removeVisibilityGroup(
        { getters }: ActionContext<QueueStateModule, PanelState>,
        name: string,
    ): void {
        getters.storageVisibility.removeVisibilityGroup(name);
    },
};
export default actions;
