








































































































































import Button from '@/atomics/Button.vue';
import Dropdown, { DropdownItem } from '@/components/Dropdown.vue';
import Icon from '@/components/Icon.vue';
import ModalAttachQueue from '@/components/agent/ModalAttachQueue.vue';
import ModalUpdateAgent from '@/components/agent/ModalUpdateAgent.vue';
import Call from '@/components/queue/Call.vue';
import Tooltip from '@/components/Tooltip.vue';
import {
    AgentStatus,
    AgentCustomStatus,
    AgentConduct,
} from '@/utils/agent';
import { VisionMode } from '@/utils/vision';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Agent as StoredAgent } from '@/store/modules/agent';
import { LocaleMessages } from 'vue-i18n';
import Chronometer from '@/atomics/Chronometer.vue';

export interface Tier {
    agent: string;
    queue: string;
}

@Component({
    components: {
        Button,
        Dropdown,
        Icon,
        ModalAttachQueue,
        Tooltip,
        Call,
        ModalUpdateAgent,
        Chronometer,
    },
})
export default class Agent extends Vue {
    @Prop(Boolean) readonly moreDetail!: boolean;

    @Prop(String) readonly name!: string;

    @Prop(Boolean) readonly connected!: boolean;

    @Prop(Array) readonly queues!: Array<string>;

    @Prop({ default: VisionMode.Minimized }) readonly visionMode!: VisionMode;

    @Prop(Number) readonly loadedTime!: number;

    @Prop(Boolean) readonly idleTimeHidden!: boolean;

    @Prop(Boolean) readonly show!: boolean;

    dropdownItems: Array<DropdownItem> = [
        {
            name: this.$t('assign_queue'),
            action: () => { (this.$refs.modalAttachQueue as ModalAttachQueue).toogleModal(); },
        },
        {
            name: this.$t('edit_agent'),
            action: () => { (this.$refs.modalUpdateAgent as ModalUpdateAgent).toogleModal(); },
        },
        {
            name: this.$t('hangup_call'),
            action: () => {
                const call = this.$store.getters.getFirstCallIdByAgent(this.name);
                if (call !== '') {
                    this.$store.dispatch('hangupCall', { name: this.name, call });
                }
            },
        },
    ]

    get nameAgent(): string {
        return this.$store.getters.agentName(this.name);
    }

    get nameAgentExtension(): string {
        return this.$store.getters.agentExtensionName(this.name);
    }

    get isInactive(): boolean {
        return this.getQueueCalls.length <= 0 && this.getCallStates.length <= 0;
    }

    get agentCustomStatus(): AgentCustomStatus {
        const agentConduct: AgentConduct = {
            connected: this.connected && !this.agent.isLoading,
            active: !this.isInactive,
        };
        return AgentCustomStatus.fromPanel(
            this.agent.status,
            this.agent.state,
            agentConduct,
            this.agent.previousState,
        );
    }

    get getQueueCalls() {
        return Array.from(this.agent.queueCalls.values());
    }

    get getCallStates() {
        return this.$store.getters.agentCallStates(this.name);
    }

    get isAvailable(): boolean {
        return AgentStatus.isAvailable(this.agent.status);
    }

    get agent(): StoredAgent {
        return this.$store.getters.getAgentByName(this.name) as StoredAgent;
    }

    get customStatusTranslation(): string | LocaleMessages {
        return this.agent.doNotDisturb
            ? this.$t('dnd')
            : this.$t(this.agentCustomStatus.translation());
    }

    get tooltipName(): string {
        if (this.nameAgent !== this.nameAgentExtension) {
            return `(${this.nameAgentExtension}) ${this.nameAgent}`;
        }
        return this.nameAgent;
    }

    calcSecs(secs: number): number {
        const freeTime = (Date.now() - this.loadedTime) / 1000;
        return secs + freeTime;
    }
}
