









































import { Vue, Component, Prop } from 'vue-property-decorator';
import Toogle from '@/components/form/Toogle.vue';
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';
import idealName from '@/utils/ideal-name';
import capitalize from '@/utils/text';
import { Agent } from '@/store/modules/agent';

@Component({
    components: {
        Toogle,
        Modal,
        Icon,
    },
})
export default class ModalAttachQueue extends Vue {
    @Prop(Array) readonly queues!: Array<string>;

    @Prop() readonly agent!: Agent;

    idealName = idealName;

    capitalize = capitalize;

    isAttachedQueue(queue: string): boolean {
        return this.$store.getters.isAgentAttachedToQueue(this.agent.name, queue);
    }

    queueName(queueName: string): string {
        return this.$store.getters.queueName(queueName);
    }

    attachQueue(queue: string) {
        if (this.isAttachedQueue(queue)) {
            this.$store.dispatch('dettachQueue', { name: this.agent.name, queue });
        } else {
            this.$store.dispatch('attachQueue', { name: this.agent.name, queue });
        }
    }

    toogleModal() {
        (this.$refs.modalAssignament as ModalAttachQueue).toogleModal();
    }
}
