type Settings = {
    idleTimeHidden: boolean;
    advancedStats: boolean;
}

export default class Setting {
    private _settings: Settings;

    constructor(settings: Settings) {
        this._settings = settings;
    }

    public static fromPanel(): Setting {
        const storedSettings = window.localStorage.getItem('settings');
        const settings = storedSettings ? JSON.parse(storedSettings) : { idleTimeHidden: true, advancedStats: false };
        return new Setting(settings);
    }

    get idleTimeHidden(): boolean {
        return this._settings.idleTimeHidden;
    }

    get advancedStats(): boolean {
        return this._settings.advancedStats;
    }

    toogleIdleTime(): void {
        this._settings.idleTimeHidden = !this._settings.idleTimeHidden;
        this.storeSettings();
    }

    toogleAdvancedStats(): void {
        this._settings.advancedStats = !this._settings.advancedStats;
        this.storeSettings();
    }

    private storeSettings(): void {
        window.localStorage.setItem('settings', JSON.stringify(this._settings));
    }
}
