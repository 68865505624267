







































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

@Component({
    components: { Icon },
})
export default class Notification extends Vue {
    @Prop(String) readonly type!: string;

    @Prop(String) readonly text!: string;

    protected get iconName(): string {
        const cases: Record<'info' | 'warning' | 'error' | 'success', string> = {
            info: 'info',
            warning: 'warning',
            error: 'error',
            success: 'success',
        };

        return cases[this.type as 'info' | 'warning' | 'error' | 'success'];
    }

    protected get iconClass(): string {
        const cases: Record<'info' | 'warning' | 'error' | 'success', string> = {
            info: 'text-indigo-600',
            warning: 'text-yellow-600',
            error: 'text-red-600',
            success: 'text-green-600',
        };

        return cases[this.type as 'info' | 'warning' | 'error' | 'success'];
    }

    protected get box(): string {
        const cases: Record<'info' | 'warning' | 'error' | 'success', string> = {
            info: 'bg-indigo-100 dark:bg-indigo-800 text-indigo-600 dark:text-indigo-300',
            warning: 'bg-yellow-100 dark:bg-yellow-800 text-yellow-600 dark:text-yellow-300',
            error: 'bg-red-100 dark:bg-red-800 text-red-600 dark:text-red-300',
            success: 'bg-green-100 dark:bg-green-800 text-green-600 dark:text-green-300',
        };

        return cases[this.type as 'info' | 'warning' | 'error' | 'success'];
    }

    protected mounted(): void {
        this.playInitialSound();
    }

    // eslint-disable-next-line class-methods-use-this
    protected playInitialSound(): void {
        const element = document.getElementById('warningMessage') as HTMLAudioElement;

        if (element) {
            element.play();
        }
    }

    hide() {
        this.$emit('hideNotification');
    }

    // eslint-disable-next-line class-methods-use-this
    private setDoNotShowNotifications(): void {
        window.localStorage.setItem('showNotifications', 'false');
        this.hide();
    }
}
