



















































































import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';
import { LocaleMessages } from 'vue-i18n';
import Icon from '@/components/Icon.vue';

export interface DropdownItem {
    icon?: string;
    iconFilled?: boolean;
    divider?: boolean;
    name: string | LocaleMessages;
    dataFilter?: string;
    isSelected?: boolean;
}

@Component({
    components: { Icon },
})
export default class Dropdown extends Vue {
    @Prop({ default: () => [] }) dropdownItems!: Array<DropdownItem>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() readonly param!: any;

    @Prop(Boolean) readonly right!: boolean;

    @Prop(Boolean) readonly disabled!: boolean;

    @Prop(String) readonly customClass!: string;

    isCursorOutside = true;

    isOpen = false;

    selectedOptions: DropdownItem[] = [];

    protected get noSelectedOptions(): boolean {
        const zeroOptions = 0;
        return this.selectedOptions.length === zeroOptions;
    }

    open(): void {
        this.isOpen = !this.isOpen && !this.disabled;
    }

    selectOption(item: DropdownItem): void {
        this.isOpen = !this.isOpen;

        const dataLength = {
            zero: 0,
            one: 1,
        };

        const index = this.selectedOptions.findIndex((option) => option === item);

        if (index !== undefined && index >= dataLength.zero) {
            this.selectedOptions.splice(index, dataLength.one);
        } else this.selectedOptions.push(item);

        this.$emit('click', this.selectedOptions);
    }

    removeSelectedOption(item: DropdownItem): void {
        this.selectOption(item);
        this.isOpen = false;
    }

    closeDropdown(): void {
        this.isOpen = false;
    }
}
