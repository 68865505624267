






























import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import Radio from '@/components/form/Radio.vue';
import QueuesFiler from '@/core/queues/Filter';
import Vision, { VisionMode } from '@/utils/vision';
import Icon from '@/components/Icon.vue';
import Dropdown from '@/components/Dropdown.vue';

@Component({
    components: {
        Radio,
        Dropdown,
        Icon,
    },
})
export default class QueuePanelFilter extends Vue {
    @Prop(Boolean) onlyAgents!: boolean;

    @Prop(Boolean) onlyStats!: boolean;

    @Prop(QueuesFiler) filter!: QueuesFiler;

    @Prop(Number) readonly visionMode!: VisionMode;

    @Prop(Vision) readonly vision!: Vision;

    get dropdownItems(): Array<any> {
        return [
            {
                name: 'Ver todo',
                action: () => this.showAll(),
                checked: this.isAllVisible,
                dataFilter: 'show-all',
            },
            {
                name: 'Solo agentes',
                action: () => this.enableOnlyAgents(),
                checked: this.onlyAgents,
                dataFilter: 'only-agents',
            },
            {
                name: 'Solo estadisticas',
                action: () => this.enableOnlyStats(),
                checked: this.onlyStats,
                dataFilter: 'only-stats',
            },
        ];
    }

    get isAllVisible(): boolean {
        return !this.onlyAgents && !this.onlyStats;
    }

    showAll(): void {
        this.filter.disableOnlyAgents();
        this.filter.disableOnlyStats();
    }

    enableOnlyAgents(): void {
        this.filter.enableOnlyAgents();
        this.filter.disableOnlyStats();
    }

    enableOnlyStats(): void {
        this.filter.enableOnlyStats();
        this.filter.disableOnlyAgents();
    }
}
