import Vue from 'vue';
import Vuex from 'vuex';
import AgentModule from '@/store/modules/agent';
import QueueModule from '@/store/modules/queue';
import state from '@/store/state';

Vue.use(Vuex);

export function createStore() {
    return new Vuex.Store({
        mutations: {
            changeUser(states, payload) {
                // eslint-disable-next-line no-param-reassign
                states.user = payload;
            },
        },
        actions: {
            changeUser({ commit }, payload) {
                commit('changeUser', payload);
            },
        },
        modules: {
            agent: AgentModule(),
            queue: QueueModule(),
        },
        state,
    });
}

export default createStore();
