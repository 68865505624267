






















import { Vue, Component, Prop } from 'vue-property-decorator';
import Select from '@/components/form/Select.vue';

export interface TabOption {
    name: string;
    value: string;
    selected: boolean;
}

@Component({ components: { Select } })
export default class Tabs extends Vue {
    @Prop(Array) readonly options!: Array<TabOption>;

    @Prop(String) readonly optionClass!: string;

    @Prop({ default: 'Select a tab' }) readonly label!: string;

    internalOptions!: Array<TabOption>;

    selected!: TabOption;

    created() {
        this.internalOptions = this.options;
        const selected = this.internalOptions.find((option: TabOption) => option.selected);
        if (selected) this.selected = selected;
    }

    onChange(option: TabOption) {
        this.unselectAll();
        const optionSelected = this.internalOptions.find((internalOption: TabOption) => internalOption === option);
        if (optionSelected) {
            optionSelected.selected = true;
            this.$emit('change', optionSelected.value);
        }
    }

    onMobileChange(values: Array<TabOption>) {
        const [value] = values;
        this.$emit('change', value);
    }

    unselectAll() {
        this.internalOptions.map((option: TabOption) => Object.assign(option, { selected: false }));
    }
}
