export const urlApi = process.env.VUE_APP_URL_API;
export const urlWebSocket = process.env.VUE_APP_URL_WEB_SOCKET;

export function currentUrl() {
    return window.location.href;
}

export function currentPath() {
    return window.location.pathname;
}
