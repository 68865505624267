import {
    AgentStateModule,
    Agent,
    HangUpCall,
    AgentInputs,
} from '@/store/modules/agent/types';
import {
    UpdatedTiers,
    UpdatedCallStates,
    UpdatedQueueCalls,
    ChangedAgentState,
    ChangedAgentStatus,
    AttachedTier,
    UpdatedAgentRegistration,
    UpdateLoadingAgent,
    UpdatedLastActivityAtSecs,
    ChangeAgentDoNotDisturb,
} from '@/store/modules/agent/events';
import { ActionContext } from 'vuex';
import { PanelState } from '@/store/state';
import { ChronometerClockManagerInstance } from '@/utils/time_manager';

const actions = {
    addAgentToContainer({ commit }: ActionContext<AgentStateModule, PanelState>, agent: Agent) {
        commit('addAgentToContainer', agent);
    },
    updateTiers({ commit }: ActionContext<AgentStateModule, PanelState>, payload: UpdatedTiers) {
        commit('updateTiers', payload);
    },
    updateCallStates({ commit }: ActionContext<AgentStateModule, PanelState>, payload: UpdatedCallStates) {
        commit('updateCallStates', payload);
    },
    updateQueueCalls({ commit }: ActionContext<AgentStateModule, PanelState>, payload: UpdatedQueueCalls) {
        commit('updateQueueCalls', payload);
    },
    changeAgentState({ commit }: ActionContext<AgentStateModule, PanelState>, payload: ChangedAgentState) {
        commit('changeAgentState', payload);
    },
    changeAgentStatus({ commit }: ActionContext<AgentStateModule, PanelState>, payload: ChangedAgentStatus) {
        commit('changeAgentStatus', payload);
    },
    putAgentInDoNotDisturb({ commit }: ActionContext<AgentStateModule, PanelState>, payload: ChangeAgentDoNotDisturb) {
        commit('putAgentInDoNotDisturb', payload);
    },
    attachQueue({ state }: ActionContext<AgentStateModule, PanelState>, { name, queue }: AttachedTier) {
        const agent = state.agentsContainer[name];
        agent.channel.push('attach_queue', { queue });
    },
    dettachQueue({ state }: ActionContext<AgentStateModule, PanelState>, { name, queue }: AttachedTier) {
        const agent = state.agentsContainer[name];

        if (!agent) return;

        agent.channel.push('detach_queue', { queue });
    },
    hangupCall({ state }: ActionContext<AgentStateModule, PanelState>, { name, call }: HangUpCall) {
        const agent = state.agentsContainer[name];
        agent.channel.push('hangup', { call });
    },
    changeAgentMetadata({ commit }: ActionContext<AgentStateModule, PanelState>, payload: AgentInputs) {
        commit('changeAgentMetadata', payload);
    },
    updateAgentMetadata({ state }: ActionContext<AgentStateModule, PanelState>, { name, metadata }: AgentInputs) {
        const attrs = { name: metadata.name };
        const agent = state.agentsContainer[name];
        agent.channel.push('update_agent', { agent: attrs });
    },
    updateAgentRegistration(
        { commit }: ActionContext<AgentStateModule, PanelState>,
        payload: UpdatedAgentRegistration,
    ) {
        commit('updateAgentRegistration', payload);
    },
    enableAgentDoNotDisturb({ state }: ActionContext<AgentStateModule, PanelState>, name: string) {
        const agent = state.agentsContainer[name];
        agent.channel.push('enable_do_not_disturb', {});
    },
    disableAgentDoNotDisturb({ state }: ActionContext<AgentStateModule, PanelState>, name: string) {
        const agent = state.agentsContainer[name];
        agent.channel.push('disable_do_not_disturb', {});
    },
    isLoadingAgent(
        { commit }: ActionContext<AgentStateModule, PanelState>,
        updateLoadingAgent: UpdateLoadingAgent,
    ) {
        commit('isLoadingAgent', updateLoadingAgent);
    },
    updateLastActivityAtSecs(
        { commit }: ActionContext<AgentStateModule, PanelState>,
        updatedLastActivityAtSecs: UpdatedLastActivityAtSecs,
    ) {
        commit('updateLastActivityAtSecs', updatedLastActivityAtSecs);
    },
    refreshLastAgentActivity({ state, dispatch }: ActionContext<AgentStateModule, PanelState>, name: string) {
        const agent = state.agentsContainer[name];
        agent.channel.push('refresh_last_activity', {}).receive('ok', (res) => {
            const updatedLastActivityAtSecs: UpdatedLastActivityAtSecs = {
                lastActivityAtSecs: res.last_activity_secs,
                name,
            };

            ChronometerClockManagerInstance.resetClock(
                updatedLastActivityAtSecs.name,
                updatedLastActivityAtSecs.lastActivityAtSecs,
            );
            dispatch('updateLastActivityAtSecs', updatedLastActivityAtSecs);
        });
    },
    refreshConnectedAgentsLastActivity({ state, dispatch }: ActionContext<AgentStateModule, PanelState>) {
        Object.entries(state.agentsContainer).forEach(([name, agent]) => {
            if (agent.connected) {
                dispatch('refreshLastAgentActivity', name);
            }
        });
    },
};
export default actions;
