














































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

export interface MenuLink {
    icon?: string;
    title: string;
    url: string;
    selected: boolean;
}

@Component({ components: { Icon } })
export default class Sidebar extends Vue {
    isDesktopExpanded = false;

    isMobileExpanded = false;

    mobileExpanded = false;

    @Prop({ default: () => [] }) menuLinks!: Array<MenuLink>;

    toogleDesktop() {
        this.isDesktopExpanded = !this.isDesktopExpanded;
        this.$emit('changeDesktop', this.isDesktopExpanded);
    }

    toogleMobile() {
        this.isMobileExpanded = !this.isMobileExpanded;

        const time = this.isMobileExpanded ? 0 : 300;
        setTimeout(() => {
            this.mobileExpanded = this.isMobileExpanded;
        }, time);
    }
}
