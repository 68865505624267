import {
    Queue,
    Tier,
    QueueStateModule,
    QueueMemberCall,
    QueueMetadata,
    QueueStats,
} from '@/store/modules/queue/types';
import { OfferAgent } from '@/store/modules/agent';
import idealName from '@/utils/ideal-name';
import capitalize from '@/utils/text';
import StorageQueueVisibility from '@/core/queues/StorageVisibility';
import { VisibilityGroup } from '@/core/queues/Queue';

export const maxQueuesVisibleAllowed = 30;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getters: any = {
    getQueueByName: (
        state: QueueStateModule,
    ) => (queueName: string): Queue => state.queuesContainer[queueName] ?? {} as Queue,

    queueAgents: (state: QueueStateModule) => (queueName: string): Array<string> => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.tiers.map((tier: Tier) => tier.agent);
    },
    queueMembersCount: (state: QueueStateModule) => (queueName: string): number => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.membersCount;
    },
    queueCallsAnswered: (state: QueueStateModule) => (queueName: string): number => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.callsAnswered;
    },
    queueMemberCall: (state: QueueStateModule) => (queueName: string, uuid: string): QueueMemberCall | object => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.memberCalls.get(uuid) || {};
    },
    queueMetadata: (state: QueueStateModule) => (queueName: string): QueueMetadata => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.metadata;
    },
    queueOfferAgents: (state: QueueStateModule) => (queueName: string): Map<string, OfferAgent> => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.agentOffers;
    },
    queueName: (state: QueueStateModule) => (queueName: string): string => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue?.metadata.name || capitalize(idealName(queue?.name ?? ''));
    },
    queueTiers: (state: QueueStateModule) => (queueName: string): Array<Tier> => {
        const queue: Queue = state.queuesContainer[queueName];
        return queue.tiers;
    },
    queueNames: (state: QueueStateModule) => (): Array<string> => Object.keys(state.queuesContainer),
    isVisibleQueue: (state: QueueStateModule) => (queueName: string): boolean => state.queuesContainer[queueName]
        ?.visible,
    queueStats: (state: QueueStateModule) => (
        queueName: string,
    ): QueueStats | undefined => state.queuesContainer[queueName].stats,

    allQueuesAreVisible: (state: QueueStateModule) => (): boolean => Object.keys(
        state.queuesContainer,
    ).every((queueName) => state.queuesContainer[queueName].visible),

    noneQueueIsVisible: (state: QueueStateModule) => (): boolean => Object.keys(
        state.queuesContainer,
    ).every((queueName) => !state.queuesContainer[queueName].visible),

    getVisibleQueues: (state: QueueStateModule) => (): Queue[] => Object.keys(
        state.queuesContainer,
    ).reduce((acc, queueName) => {
        if (state.queuesContainer[queueName].visible) acc.push(state.queuesContainer[queueName]);

        return acc;
    }, [] as Queue[]),

    getNotVisibleQueues: (state: QueueStateModule) => (): Queue[] => Object.keys(
        state.queuesContainer,
    ).reduce((acc, queueName) => {
        if (!state.queuesContainer[queueName].visible) acc.push(state.queuesContainer[queueName]);

        return acc;
    }, [] as Queue[]),

    visibleQueuesAreGreaterThanMaxAllowed: (
        _state: QueueStateModule, gettersStore: typeof getters,
    ) => (): boolean => gettersStore.getVisibleQueues().length > maxQueuesVisibleAllowed,

    maxQueuesAllowedAreVisible: (
        _state: QueueStateModule, gettersStore: typeof getters,
    ) => (): boolean => gettersStore.getVisibleQueues().length === maxQueuesVisibleAllowed,

    storageVisibility: (state: QueueStateModule): StorageQueueVisibility => state.storageVisibility,

    getVisibilityGroups: (
        state: QueueStateModule,
    ) => (): VisibilityGroup[] => state.storageVisibility.getVisibilityGroups(),
};
export default getters;
