import Filter from './panel/Filter';
import Setting from './panel/Setting';

export default class Panel {
    private _filter: Filter;

    private _setting: Setting;

    private constructor(filter: Filter, setting: Setting) {
        this._filter = filter;
        this._setting = setting;
    }

    static fromView(filter: Filter, setting: Setting): Panel {
        return new Panel(filter, setting);
    }

    get filter(): Filter {
        return this._filter;
    }

    get setting(): Setting {
        return this._setting;
    }
}
