import Vue from 'vue';
import {
    UpdatedQueueCallsAnswered,
    UpdatedQueueMembersCount,
    UpdatedQueueMemberCalls,
    UpdatedQueueAgentOffers,
    UpdatedQueueTiers,
    ChangedQueueVisibility,
    UpdatedQueueMetadata,
    UpdateLoadingQueue,
    UpdatedQueueStats,
    UpdateAgentState,
} from '@/store/modules/queue/events';
import { Queue, QueueStateModule, Tier } from '@/store/modules/queue/types';
import { OfferAgent } from '@/store/modules/agent';
import StorageQueueVisibility from '@/core/queues/StorageVisibility';

const mutations = {
    addQueueToContainer(state: QueueStateModule, queue: Queue) {
        Vue.set(state.queuesContainer, queue.name, queue);
    },
    updateQueueCallsAnswered(state: QueueStateModule, { name, callsAnswered }: UpdatedQueueCallsAnswered) {
        const queue = { ...state.queuesContainer[name], callsAnswered };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    updateQueueAgentOffers(state: QueueStateModule, { name, agentOffers }: UpdatedQueueAgentOffers) {
        const queue = {
            ...state.queuesContainer[name],
            agentOffers: new Map(Object.values(agentOffers)
                .map((offer) => [(offer as OfferAgent).agent, offer])),
        };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    updateQueueTiers(state: QueueStateModule, { name, tiers }: UpdatedQueueTiers) {
        tiers.sort((a: Tier, b: Tier) => {
            if (a.agent < b.agent) {
                return 1;
            }
            return -1;
        });
        const queue = { ...state.queuesContainer[name], tiers };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    updateQueueMemberCalls(state: QueueStateModule, { name, memberCalls }: UpdatedQueueMemberCalls) {
        const queue = { ...state.queuesContainer[name], memberCalls: new Map(Object.entries(memberCalls)) };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    updateQueueMembersCount(state: QueueStateModule, { name, membersCount }: UpdatedQueueMembersCount) {
        const queue = { ...state.queuesContainer[name], membersCount };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    changeQueueVisibility(state: QueueStateModule, { name, visible }: ChangedQueueVisibility) {
        const queue = { ...state.queuesContainer[name], visible };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    changeQueueMetadata(state: QueueStateModule, { name, metadata }: UpdatedQueueMetadata) {
        const queue = {
            ...state.queuesContainer[name],
            metadata: { ...state.queuesContainer[name].metadata, ...metadata },
        };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    isLoadingQueue(state: QueueStateModule, { name, isLoading }: UpdateLoadingQueue) {
        const queue = { ...state.queuesContainer[name], isLoading };
        state.queuesContainer = Object.assign(state.queuesContainer, { [name]: queue });
    },
    updateQueueStats(state: QueueStateModule, { queueStats }: UpdatedQueueStats) {
        queueStats.forEach((stats) => { // eslint-disable-line @typescript-eslint/camelcase
            if (state.queuesContainer[stats.callcenter_queue]) { // eslint-disable-line @typescript-eslint/camelcase
                // eslint-disable-next-line @typescript-eslint/camelcase
                const queue = { ...state.queuesContainer[stats.callcenter_queue], stats };
                // eslint-disable-next-line @typescript-eslint/camelcase
                state.queuesContainer = Object.assign(state.queuesContainer, { [stats.callcenter_queue]: queue });
            }
        });
    },
    setStorageVisibility(state: QueueStateModule, storage: StorageQueueVisibility): void {
        state.storageVisibility = storage;
    },

    updateAgentState(state: QueueStateModule, data: UpdateAgentState): void {
        const agent = state.queuesContainer[data.queueName].tiers.find((tier) => tier.agent === data.agentName);

        if (agent) agent.state = data.state;
    },
};
export default mutations;
