














import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

@Component({ components: { Icon } })
export default class IndicatorQueue extends Vue {
    @Prop(String) readonly icon!: string;

    @Prop(String) readonly iconClass!: string;

    @Prop(Boolean) readonly iconFilled!: boolean;

    @Prop(Number) readonly indicator!: number;

    @Prop(String) readonly name!: string;
}
