















































































import {
    Component,
    Vue,
    Prop,
    Watch,
} from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';
import Modal from '@/components/Modal.vue';
import { Queue as QueueStore } from '@/store/modules/queue';
import { maxQueuesVisibleAllowed } from '@/store/modules/queue/getters';
import QueuesFilter from '@/components/select/multiple/queue/QueuesFilter.vue';
import QueuesVisibilityGroup from '@/components/select/multiple/queue/QueuesVisibilityGroup.vue';

@Component({
    components: {
        QueuesFilter,
        Modal,
        Icon,
        QueuesVisibilityGroup,
    },
})
export default class SelectMultipleQueue extends Vue {
    @Prop(Array) readonly queues!: Array<QueueStore>;

    @Prop(String) readonly currentDomain!: string;

    maxQueuesVisibleAllowed = maxQueuesVisibleAllowed;

    filteredQueues: Array<QueueStore> = [];

    isOpen = false;

    visibilityGroupName = '';

    availableTabs = {
        filter: 0,
        visibilityGroups: 1,
    }

    tabSelected = this.availableTabs.filter;

    get isQueuesFilterTabSelected(): boolean {
        return this.tabSelected === this.availableTabs.filter;
    }

    get modal(): Modal {
        return this.$refs.modal as Modal;
    }

    applyFilter(queues: QueueStore[]): void {
        queues.forEach((queue) => {
            this.$store.dispatch('changeQueueVisibility', {
                name: queue.name,
                visible: queue.visible,
            });
        });

        this.toggleSelect();
    }

    toggleSelect(): void {
        this.modal.toogleModal();

        this.isOpen = this.modal.isOpen;
    }

    changeTabTo(tabIndex: number): void {
        this.tabSelected = tabIndex;
    }

    @Watch('isOpen')
    initQueues() {
        this.filteredQueues = [...this.queues.map((queue) => ({ ...this.$store.getters.getQueueByName(queue.name) }))];

        this.tabSelected = this.availableTabs.filter;
    }
}
