


































































/* eslint-disable no-param-reassign */
import {
    Component,
    Vue,
    Prop,
    Watch,
} from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';
import { Queue as QueueStore } from '@/store/modules/queue';
import { maxQueuesVisibleAllowed } from '@/store/modules/queue/getters';

@Component({
    components: {
        Icon,
    },
})
export default class QueuesAsOptions extends Vue {
    @Prop(Array) readonly queues!: Array<QueueStore>;

    search = '';

    maxQueuesVisibleAllowed = maxQueuesVisibleAllowed;

    get allQueuesAreVisible(): boolean {
        return this.queues.every((queue) => queue.visible);
    }

    toggleQueueVisibility(queue: QueueStore): void {
        if (this.allQueuesAreVisible) {
            this.hideAllQueuesExcept(queue);

            return;
        }

        if (this.maxQueuesAllowedAreVisible() && !this.isVisibleQueue(queue)) return;

        queue.visible = !queue.visible;

        if (this.noneQueueIsVisible()) this.showAllQueues();
    }

    isQueueFiltered(queue: QueueStore): boolean {
        return this.isVisibleQueue(queue) && !this.allQueuesAreVisible;
    }

    // eslint-disable-next-line class-methods-use-this
    isVisibleQueue(queue: QueueStore): boolean {
        return queue.visible;
    }

    hideAllQueuesExcept(queue: QueueStore): void {
        this.queues.forEach((filteredQueue: QueueStore) => {
            // eslint-disable-next-line no-param-reassign
            if (filteredQueue.name !== queue.name) filteredQueue.visible = false;
        });
    }

    maxQueuesAllowedAreVisible(): boolean {
        return this.queues.filter((queue) => queue.visible).length === this.maxQueuesVisibleAllowed;
    }

    noneQueueIsVisible(): boolean {
        return this.queues.every((queue) => !queue.visible);
    }

    showAllQueues(): void {
        this.queues.forEach((queue) => { queue.visible = true; });
    }

    selectAllQueues(): void {
        this.queues.forEach((queue) => {
            queue.visible = false;

            this.toggleQueueVisibility(queue);
        });
    }

    @Watch('search')
    searchQueues(): void {
        const queues = this.$refs.queues as HTMLElement[];

        queues.forEach((queue) => {
            queue.classList.remove('hidden');
        });

        const queuesNotInSearch = queues.filter((_queue, index) => !this.queues[index].name.includes(this.search));

        queuesNotInSearch.forEach((queue) => {
            queue.classList.add('hidden');
        });
    }
}
