






























import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';

@Component
export default class InfoBox extends Vue {
    @Prop(String) title!: string;

    @Prop(String) subTitle!: string;

    @Prop(String) customClass!: string;
}
