










































import {
    Component,
    Vue,
} from 'vue-property-decorator';
import { Queue as QueueStore } from '@/store/modules/queue';
import { VisibilityGroup } from '@/core/queues/Queue';

@Component
export default class QueuesVisibilityGroup extends Vue {
    visibilityGroups: VisibilityGroup[] = [];

    created() {
        this.init();
    }

    init(): void {
        this.visibilityGroups = [...this.$store.getters.getVisibilityGroups()];
    }

    removeVisibilityGroup(name: string): void {
        this.$store.dispatch('removeVisibilityGroup', name);

        this.init();
    }

    loadVisibilityGroup(queues: QueueStore[]): void {
        this.$emit('visibilityGroupWasLoaded', queues);
    }
}
