import { QueueStateModule } from '@/store/modules/queue';
import { Channel } from 'phoenix';
import WebSocket from '@/socket';
import { Store } from 'vuex';
import { State } from '@/utils/tier/state';

export default function createQueueChannel(
    name: string,
    socket: WebSocket,
    store: Store<QueueStateModule>,
    onInit: () => void,
): Channel {
    const channel = socket.channel(`queue:${name}`);

    channel.on('bridge_agent_start', (data) => {
        store.dispatch('updateQueueCallsAnswered', { callsAnswered: data.calls_answered, name });
    });

    channel.on('bridge_agent_end', (data) => {
        store.dispatch('updateQueueCallsAnswered', { callsAnswered: data.calls_answered, name });
    });

    channel.on('member_queue_end', (data) => {
        store.dispatch('updateQueueMemberCalls', { memberCalls: data.member_calls || {}, name });
    });

    channel.on('member_queue_start', (data) => {
        store.dispatch('updateQueueMemberCalls', { memberCalls: data.member_calls || {}, name });
    });

    channel.on('agent_offers', (data) => {
        store.dispatch('updateQueueAgentOffers', { agentOffers: data.agent_offers || {}, name });
    });

    channel.on('members_count', (data) => {
        store.dispatch('updateQueueMembersCount', { membersCount: data.members_count, name });
    });

    channel.on('tier_added', (data) => {
        store.dispatch('updateQueueTiers', { tiers: data.tiers, name });
    });

    channel.on('tier_removed', (data) => {
        store.dispatch('updateQueueTiers', { tiers: data.tiers, name });
    });

    channel.on('init_queue', (data) => {
        store.dispatch('updateQueueTiers', { tiers: data.tiers || [], name });
        store.dispatch('updateQueueCallsAnswered', { callsAnswered: data.calls_answered, name });
        onInit();
    });

    channel.on('tier_state_change', (data) => {
        store.commit('updateAgentState', {
            queueName: data.queue,
            agentName: data.agent,
            state: data.state === 'ready'
                ? State.Ready
                : State.Absent,
        });
    });

    return channel;
}
