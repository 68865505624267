
























import { Component, Vue, Watch } from 'vue-property-decorator';
import Veil from '@/atomics/Veil.vue';

@Component({ components: { Veil } })
export default class Login extends Vue {
    @Watch('isAuthLoading')
    onIsAuthChanged() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!this.$auth.loading && this.$auth.isAuthenticated) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.$auth.getTokenSilently().then((token) => {
                sessionStorage.setItem('token', token);
                this.$router.push('/panel');
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
        } else if (!this.$auth.isAuthenticated) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.$auth.loginWithRedirect();
        }
    }

    get isAuthLoading() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return this.$auth.loading;
    }
}
