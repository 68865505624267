import getters from '@/store/modules/agent/getters';
import actions from '@/store/modules/agent/actions';
import mutations from '@/store/modules/agent/mutations';
import state from '@/store/modules/agent/state';
import { Module } from 'vuex';
import { AgentStateModule } from '@/store/modules/agent/types';
import { PanelState } from '@/store/state';

const AgentModule = (): Module<AgentStateModule, PanelState> => ({
    state: state(),
    getters,
    mutations,
    actions,
});

export * from '@/store/modules/agent/types';
export default AgentModule;
