


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { CustomerInfo } from '@/core/userinfo';
import Dropdown, { DropdownItem } from '@/components/Dropdown.vue';
import Icon from '@/components/Icon.vue';

export interface BreadcrumItem {
    name: string;
    url: string;
}

@Component({
    components: {
        Dropdown,
        Icon,
    },
})
export default class Breadcrum extends Vue {
    @Prop({ default: () => [] }) levels!: Array<BreadcrumItem>;

    customersToShow = [] as Array<CustomerInfo>;

    currentDomain = '';

    get pbxAsOptions(): Array<DropdownItem> {
        return this.customersToShow.map((customer, index) => ({
            name: customer.domain.split('.')[0],
            action: () => {
                this.changeDomain(index);
            },
            icon: this.isDomainSelected(customer.domain) ? 'check' : '',
            isSelected: this.isDomainSelected(customer.domain),
        }));
    }

    created() {
        this.$root.$on('customers', (customers: Array<CustomerInfo>) => {
            this.$set(this, 'customersToShow', [...customers]);
        });

        this.$root.$on('currentDomain', (currentDomain: string) => {
            this.currentDomain = currentDomain;
        });
    }

    changeDomain(index: number): void {
        this.$root.$emit('changeDomain', index);
    }

    domainName(index: number): string {
        return this.customersToShow[index].domain.split('.')[0];
    }

    isDomainSelected(domain: string): boolean {
        return this.currentDomain === domain;
    }
}
