












import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';

@Component({ components: { Icon } })
export default class Tooltip extends Vue {
    isVisible = false;

    @Prop(String) readonly icon!: string;

    @Prop(String) readonly iconClass!: string;

    @Prop(String) readonly content!: string;

    @Prop(Boolean) readonly iconFilled!: boolean;

    @Prop({ default: 'top' }) readonly direction!: string;

    get directionTooltip() {
        switch (this.direction) {
            case 'top':
                return '-top-10 left-0';
            case 'bottom':
                return 'top-full left-0';
            case 'left':
                return 'top-0 right-full';
            case 'right':
                return 'top-0 left-full';
            default:
                return '-top-full left-0';
        }
    }
}
