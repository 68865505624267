import { Channel } from 'phoenix';
import WebSocket from '@/socket';

export default function createMaintenanceChannel(
    socket: WebSocket,
): Channel {
    const channel = socket.channel('maintenance');

    channel.on('restart_browser', () => {
        window.location.reload();
    });

    return channel;
}
