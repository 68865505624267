





































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
    isOpen = false;

    open = false;

    toogleModal() {
        this.isOpen = !this.isOpen;
        const time = this.isOpen ? 0 : 200;

        setTimeout(() => {
            this.open = this.isOpen;
        }, time);
    }
}
