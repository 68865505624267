export enum State {
    Idle = 'idle',
    Waiting = 'waiting',
    Receiving = 'receiving',
    InQueueCall = 'in_queue_call',
    None = '',
}

export class AgentState {
    static isIdle(state: State): boolean {
        return state === State.Idle;
    }

    static isWaiting(state: State): boolean {
        return state === State.Waiting;
    }

    static isReceiving(state: State): boolean {
        return state === State.Receiving;
    }

    static isInQueueCall(state: State, previousState: State): boolean {
        return state === State.InQueueCall || (state === State.Receiving && previousState === State.InQueueCall);
    }

    static fromString(state: string): State {
        switch (state) {
        case State.Idle:
            return State.Idle;
        case State.Waiting:
            return State.Waiting;
        case State.Receiving:
            return State.Receiving;
        case State.InQueueCall:
            return State.InQueueCall;
        default:
            return State.None;
        }
    }
}
