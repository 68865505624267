








































import { Vue, Component, Prop } from 'vue-property-decorator';
import idealName from '@/utils/ideal-name';
import Chronometer from '@/atomics/Chronometer.vue';
import Icon from '@/components/Icon.vue';
import Tooltip from '@/components/Tooltip.vue';

export enum Direction {
    Inbound = 'inbound',
    Outbound = 'outbound'
}

@Component({
    components: {
        Chronometer,
        Icon,
        Tooltip,
    },
})
export default class Call extends Vue {
    @Prop(Boolean) readonly moreDetail!: boolean;

    /* TODO(bit4bit) remover este atributo
     * con **callId** seria suficiente
     * pero el atributo no es populado siempre.
     */
    @Prop(String) readonly chronometerId!: string;

    @Prop(String) readonly callId!: string;

    @Prop(String) readonly icon!: string;

    @Prop(String) readonly content!: string;

    @Prop(Number) time!: number;

    @Prop(String) readonly did!: string;

    idealName = idealName;
}
