export enum State {
    Ready = 'ready',
    Absent = 'absent',
}

export class TierState {
    static isPaused(state: State): boolean {
        return state === State.Absent;
    }

    static isReady(state: State): boolean {
        return state === State.Ready;
    }
}
