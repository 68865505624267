




























































































































import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import Icon from '@/components/Icon.vue';
import QueuesAsOptions from '@/components/select/multiple/queue/QueuesAsOptions.vue';
import QueuesVisible from '@/components/select/multiple/queue/QueuesVisible.vue';
import { Queue as QueueStore } from '@/store/modules/queue';
import Modal from '@/components/Modal.vue';
import Tooltip from '@/components/Tooltip.vue';

@Component({
    components: {
        Icon,
        QueuesAsOptions,
        QueuesVisible,
        Modal,
        Tooltip,
    },
})
export default class QueuesFilter extends Vue {
    @Prop(Array) readonly filteredQueues!: Array<QueueStore>;

    visibilityGroupName = '';

    get visibleQueues(): QueueStore[] {
        return this.filteredQueues.filter((queue) => queue.visible);
    }

    get notVisibleQueues(): QueueStore[] {
        return this.filteredQueues.filter((queue) => !queue.visible);
    }

    applyFilter(): void {
        this.$emit('filterWasApplied', this.filteredQueues);
    }

    closeModal(): void {
        this.$emit('closeModal');
    }

    storageVisibilityGroup(): void {
        if (this.visibilityGroupName.length === 0) return;

        this.$store.dispatch('storageVisibilityGroup', {
            queues: this.filteredQueues.map((queue) => ({
                name: queue.name,
                visible: queue.visible,
            })),
            name: this.visibilityGroupName,
        });

        this.toggleVisibilityModal();
    }

    toggleVisibilityModal(): void {
        this.visibilityGroupName = '';

        const saveVisibilityModal = this.$refs.saveVisibilityGroup as Modal;

        saveVisibilityModal.toogleModal();
    }
}
