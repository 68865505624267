import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import CallsByAgentContainer from '../components/CallsByAgent/Container.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { title: () => 'Login' },
    },
    {
        path: '/panel',
        name: 'Panel',
        component: () => import('../views/PanelContainer.vue'),
        meta: { requiresAuth: true, title: () => 'MyFlexPbx' },
        children: [
            {
                path: 'agent',
                name: 'CallsByAgent',
                component: CallsByAgentContainer,
                meta: { requiresAuth: true, title: () => 'Calls By Agent' },
            },
        ],
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: () => import('../views/Unauthorized.vue'),
        meta: { title: () => 'Unauthorized' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title(to);
    });
});

export default router;
