





import {
    Vue,
    Component,
    Prop,
    Watch,
} from 'vue-property-decorator';
import { unitToString } from '@/utils/time';
import { ChronometerClockManagerInstance } from '@/utils/time_manager';

@Component
export default class Chronometer extends Vue {
    @Prop({ default: 0 }) startFrom!: number; // time in seconds

    @Prop(String) readonly id!: string;

    minutes = 0;

    seconds = 0;

    unitToString = unitToString;

    private clockManager = ChronometerClockManagerInstance;

    created() {
        this.clockManager.addClock(this.id, this.startFrom, (seconds, minutes) => {
            this.seconds = seconds;
            this.minutes = minutes;
        });
    }

    @Watch('startFrom')
    onStartFromChanged(value: number) {
        // TEL-1181
        this.clockManager.resetClock(this.id, value);
    }

    destroyed() {
        this.clockManager.removeClock(this.id);
    }
}
