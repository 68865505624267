import { Queue as QueueStore } from '@/store/modules/queue';

export default class StorageOrder {
    private storageKey: string;

    private constructor(prefix: string) {
        this.storageKey = `${prefix}-queues-in-custom-order`;
    }

    public static create(prefix: string): StorageOrder {
        return new StorageOrder(prefix);
    }

    public saveCustomOrder(queuesOrder: QueueStore[]): void {
        window.localStorage.setItem(this.storageKey, JSON.stringify(queuesOrder));
    }

    public getCustomOrder(): QueueStore[] {
        const queuesInStorage = window.localStorage.getItem(this.storageKey);

        return queuesInStorage
            ? JSON.parse(queuesInStorage)
            : [];
    }
}
