export enum Status {
    LoggedOut = 'logged_out',
    Available = 'available',
    AvailableOnDemand = 'available_on_demand',
    OnBreak = 'on_break',
    Break = 'break',
    NotAssign = 'not_assign', // custom status without relation with status
    Waiting = 'waiting',
    None = '',
}

export class AgentStatus {
    static isLoggedOut(status: Status): boolean {
        return status === Status.LoggedOut;
    }

    static isAvailable(status: Status): boolean {
        return status === Status.Available;
    }

    static isAvailableOnDemand(status: Status): boolean {
        return status === Status.AvailableOnDemand;
    }

    static isOnBreak(status: Status): boolean {
        return status === Status.OnBreak;
    }

    static isWaiting(status: Status): boolean {
        return status === Status.OnBreak;
    }

    static fromString(status: string): Status {
        const normalizedStatus = status.toLowerCase();

        switch (normalizedStatus) {
        case 'on_break':
        case 'almuerzo':
        case 'break':
        case 'lunch':
        case 'pausa':
            return Status.OnBreak;
        case 'logged_out':
            return Status.LoggedOut;
        case 'available':
            return Status.Available;
        case 'not_assign':
            return Status.NotAssign;
        case 'available_on_demand':
            return Status.AvailableOnDemand;
        default:
            return Status.None;
        }
    }
}
